import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTemp'
})
export class GetTempPipe implements PipeTransform {

  transform(template): any {

    return template[0];


  }

}
