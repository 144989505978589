import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  transform(arr): any {
    const newArr = [];
    arr.map((item, index) => {
      if (index % 2 === 0) {
        newArr[index / 2] = [item];
      }
      if (index % 2 === 1) {
        newArr[(index - 1) / 2][1] = item;
      }
    });
    return newArr;
  }

}
