import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaperService } from '../../../services/paper.service';

@Component({
  selector: 'app-paper-size',
  templateUrl: './paper-size.component.html',
  styleUrls: ['./paper-size.component.scss'],
})
export class PaperSizeComponent implements OnInit {

  @Input() value: any ;
  @Output() valueChange: EventEmitter<Function> = new EventEmitter();

  constructor(public paperService: PaperService) { }

  ngOnInit() {}

  submitValue() {
    this.valueChange.emit(this.value);
  }

  ngAfterContentInit() {
  }

}
