import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HomePrintPage } from './home-print.page';
import { RouterModule, Routes } from '@angular/router';
import { ComponentsPrintModule } from '../../components-print/components-print.module';
import { PipesModule } from '../../pipes/pipes.module';
import { SubMenuTemplateModule } from '../sub-menu-template/sub-menu-template.module';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderModule } from '../../manager/header/header.module';
const childRoutes: Routes = [
  { path: '', loadChildren: () => import('./../print-product-selection/print-product-selection.module').then(m => m.PrintProductSelectionPageModule) },
  { path: 'person', loadChildren: () => import('./../connect-person/connect-person.module').then(m => m.ConnectPersonModule) },
  { path: 'cart-order/:lastCard', loadChildren: () => import('./../print-cart-order/print-cart-order.module').then(m => m.PrintCartOrderModule) },
  { path: 'new-client', loadChildren: () => import('./../new-client/new-client.module').then(m => m.NewClientModule) },
  { path: 'existing-client', loadChildren: () => import('./../existing-client/existing-client.module').then(m => m.ExistingClientModule) },
  { path: 'selected-product/:orderProductKey', loadChildren: () => import('./../print-product-selection/print-product-selection.module').then(m => m.PrintProductSelectionPageModule) },
  { path: 'product', loadChildren: () => import('./../print-product-selection/print-product-selection.module').then(m => m.PrintProductSelectionPageModule) },
  { path: 'product/CartIndex', loadChildren: () => import('./../print-product-selection/print-product-selection.module').then(m => m.PrintProductSelectionPageModule) },
  { path: 'designs', loadChildren: () => import('./../print-design-selection/print-design-selection.module').then(m => m.PrintDesignSelectionPageModule) },
  { path: 'designs/CartIndex', loadChildren: () => import('./../print-design-selection/print-design-selection.module').then(m => m.PrintDesignSelectionPageModule) },
  { path: 'editing/CartIndex', loadChildren: () => import('./../print-editing/print-editing.module').then(m => m.PrintEditingPageModule) },
  { path: 'cart', loadChildren: () => import('./../../pages/cart/cart.module').then(m => m.CartPageModule) },
  { path: 'payment', loadChildren: () => import('./../print-payment/print-payment.module').then(m => m.PrintPaymentPageModule) },
  { path: 'designs/product', loadChildren: () => import('./../designs-product/designs-product.module').then(m => m.DesignsProductPageModule) },
  { path: 'designs/category', loadChildren: () => import('./../designs-category/designs-category.module').then(m => m.DesignsCategoryPageModule) },
  { path: 'designs/product/:sizeID', loadChildren: () => import('./../designs-product/designs-product.module').then(m => m.DesignsProductPageModule) },
  { path: 'orders/:orderID', loadChildren: () => import('./../print-details-orders/print-details-orders.module').then(m => m.PrintDetailsOrdersModule) },
  { path: 'allOrders/:userID', loadChildren: () => import('./../all-orders/all-orders.module').then(m => m.AllOrdersModule) },
  { path: 'allOrders', loadChildren: () => import('./../all-orders/all-orders.module').then(m => m.AllOrdersModule) },
  // { path: 'designs/category/:categoryID', loadChildren: './../designs-category/designs-category.module#DesignsCategoryPageModule' },
  { path: 'products', loadChildren: () => import('./../../manager/print-products/print-products.module').then(m => m.PrintProductsModule) },
  { path: 'products/:productID', loadChildren: () => import('./../../manager/print-product/print-product.module').then(m => m.PrintProductModule) },
  { path: 'editSize/:sizeID', loadChildren: () => import('./../../manager/edit-size/edit-size.module').then(m => m.EditSizeModule) },
  { path: 'definitions', loadChildren: () => import('../../setting/definition/definition.module').then(m => m.DefinitionModule) },
  { path: 'costumers', loadChildren: () => import('../../components-print/costumers/costumers.module').then(m => m.costumersModule) },
];
const routes: Routes = [
  {
    path: '',
    component: HomePrintPage,
    children: childRoutes
  },
  // { path: 'design/:designID', loadChildren: './../../pages/design/design.module#DesignPageModule', data: { noReuse: true } },
  // { path: 'design/:designID/:catID', loadChildren: './../../pages/design/design.module#DesignPageModule' , data: { noReuse: true }},
  // { path: 'cart', loadChildren: './../../pages/print-cart/print-cart.module#PrintCartPageModule' },

];
@NgModule({
  imports: [

    ComponentsPrintModule,
    CommonModule,
    FormsModule,
    PipesModule,
    IonicModule,
    TranslateModule,
    SubMenuTemplateModule,
    RouterModule.forChild(routes),
    HeaderModule
  ],
  declarations: [HomePrintPage],
  exports: [HomePrintPage],

})
export class HomePrintPageModule { }
