import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DataService } from '../../data.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class FilesService {
    public files: any = [];
    public filterType$: BehaviorSubject<string | null> = new BehaviorSubject(
        null
    );
    public filterUser$: BehaviorSubject<string | null> = new BehaviorSubject(
        null
    );
    public filterStatus$: BehaviorSubject<string | null> = new BehaviorSubject(
        null
    );
    public filesLimit$: BehaviorSubject<number | null> = new BehaviorSubject(
        20
    );
    public filterStatus: any = '';
    public filterType: any = '';
    public activeClient: any = { id: '', search: '' };
    public statusesArray: any = [];
    public typesArray: any = [];
    public statuses: any = {
        '105': this.translate.instant('582_WITHOUT_PRINTING'),
        '110': this.translate.instant('565_WAITING_FOR_PRINTING'),
        '120': this.translate.instant('583_PRINTED'),
        '140': this.translate.instant('584_SENT_TO_THE_CUSTOMER'),
    };

    constructor(
        private translate: TranslateService,
        public dataService: DataService,
        private afs: AngularFirestore,
        public AuthService: AuthService
    ) {
        this.buildStatusesArray();
        this.buildTypesArray();

        this.dataService.sizesLoad$.subscribe(() => {
            this.buildTypesArray();

        });
    }

    // get files
    getFiles() {
        if (this.files.length) {
            return;
        }

        combineLatest(
            this.filterType$,
            this.filterStatus$,
            this.filterUser$,
            this.filesLimit$
        )
            .pipe(
                switchMap(([type, status, filterUser, limit]) =>
                    this.afs.collection('files', (ref: any) => {
                        if (!filterUser && !(this.AuthService.userType == 2 || this.AuthService.userType == 3)) {
                            filterUser = this.AuthService.user['uid'];
                        }
                        if (filterUser) {
                            ref = ref.where('userCreated', '==', filterUser);
                        }


                        if (type) {
                            ref = ref.where('sizeID', '==', type);
                        }

                        if (status) {
                            ref = ref.where('statusFile', '==', status);
                        }

                        return ref.limit(limit).orderBy('time', 'desc');
                    })
                        .snapshotChanges()
                ),
                map((actions: any) => {
                    return actions.map((a) => {
                        const data: Object = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    });
                })
            )
            .subscribe((files) => {

                this.files = files;
            });
    }

    changeFilterStatus($event) {
        const value = $event ? $event.id : null;
        if (value !== this.filterStatus$.getValue()) {
            this.filterStatus$.next(value);
            this.filterStatus = new Object($event);
        }
    }

    changeFilterType($event) {
        const value = $event ? $event.id : null;
        if (value !== this.filterType$.getValue()) {

            this.filterType$.next(value);
            this.filterType = new Object($event);
        }
    }

    downloadFile(file, type) {

        const fileUrl = file[`${type}FileURL`];

        if (fileUrl) {
            this.dataService.downloadFile(
                fileUrl,
                `Artime ${file.name}.${type}`
            );
        }
    }

    readMore() {
        const currentLimit = this.filesLimit$.getValue();

        this.filesLimit$.next(currentLimit + 20);
    }

    changeStatusFile(item, event) {
        const value = _.get(event, ['detail', 'value'], null);
        if (value) {
            // update status
            this.afs.doc(`files/${item.id}`).update({
                statusFile: value,
            });
        }
    }

    buildStatusesArray() {
        const statusesArray = [];
        for (const key in this.statuses) {
            statusesArray.push({
                id: parseInt(key),
                name: this.statuses[key] || '',
            });
        }
        this.statusesArray = statusesArray;
    }

    buildTypesArray() {
        const typesArray = [];
        for (const sizeID in this.dataService.sizes) {
            typesArray.push({
                id: sizeID,
                name: this.dataService.sizes[sizeID].name || '',
            });
        }
        this.typesArray = typesArray;
    }
    getNameStatus(idStatus) {
        return this.statuses[idStatus];
    }
}
