import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, AfterContentInit, ViewEncapsulation } from '@angular/core';
import { IDynamicInput } from '../../../interfaces/general';

@Component({
  selector: 'app-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.scss'],
})
export class DynamicInputComponent implements OnInit, AfterContentInit {

  @Input() input: IDynamicInput;
  @Input() value: any;
  @Output() valueChange: EventEmitter<Function> = new EventEmitter();

  constructor() {
  }

  submitValue() {
    this.valueChange.emit(this.value);
  }

  ngAfterContentInit() {
    if (this.value === undefined && this.input.defaultValue !== undefined) {
      this.value = this.input.defaultValue;
    }
  }


  ngOnInit() {
  }

}
