import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { Observable } from 'rxjs';
import { AlertController, LoadingController, ToastController, ModalController } from '@ionic/angular';
import { TranslateProvider } from './providers';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UsersService } from './services/users.service';
import { UserType } from './manager-permission/userType';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: Observable<firebase.User> = null;
  public userProfile: any = {};
  onLogin$: Observable<any>;
  public onLoginSubject: any;
  userType = 0;
  hasPermission = false;


  constructor(
    private firebaseAuth: AngularFireAuth,
    public alertCtrl: AlertController,
    private translate: TranslateProvider,
    public loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
    private afs: AngularFirestore,
    public modalController: ModalController,
    private usersService: UsersService,
  ) {

    this.onLoginSubject = new Subject();
    this.onLogin$ = this.onLoginSubject.asObservable();


    firebaseAuth.authState.subscribe((user: any) => {
      if (user) {
        this.user = user;

        // get user profile
        this.getUserProfile();



      } else {
        this.onLoginSubject.next(false);

      }
    },
      err => {
      });

  }

  async forgotPass() {

    const alert = await this.alertCtrl.create({
      header: this.translate.get('app.pages.login.label.forgot'),
      message: this.translate.get('app.pages.login.text.forgot'),
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: this.translate.get('app.label.email')
        }
      ],
      buttons: [
        {
          text: this.translate.get('app.pages.ion-selected.text.cancelText'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: this.translate.get('303_SEND'),
          handler: async (data) => {
            const loader = await this.loadingCtrl.create({
              duration: 2000
            });

            loader.present();
            loader.onWillDismiss().then(async l => {

              this.firebaseAuth.auth.sendPasswordResetEmail(data.email);

              const toast = await this.toastCtrl.create({
                // showCloseButton: true,
                message: this.translate.get('app.pages.login.text.sended'),
                duration: 3000,
                position: 'top'
              });

              return await toast.present();
            });
          }
        }
      ]
    });

    await alert.present();
  }

  async addMail(mail: string, phoneNumber: string, displayName: string) {
    const data = { mail, phoneNumber, displayName };
    return await this.usersService.addNewUser(data)
  }



  async signup(email: string, password: string, fullName = '') {
    return this.firebaseAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((value: any) => {
        const user = firebase.auth().currentUser;
        user.updateProfile({
          displayName: fullName
        });

        // get user profile
        this.getUserProfile();

        this.onLoginSubject.next(this.user);

        const toast = this.toastCtrl.create({
          // showCloseButton: true,
          message: this.translate.get('304_REGISTRATION_HAS_BEEN_SUCCESSFULLY_COMPLETED'),
          duration: 3000,
          position: 'top'
        }).then((toast) => {
          toast.present();
        });
        this.dismiss();
        return true;

      })
      .catch(err => {

        const toast = this.toastCtrl.create({
          // showCloseButton: true,
          message: err.message,
          duration: 3000,
          position: 'top'
        }).then((toast) => {
          toast.present();
        });
        console.log('Something went wrong:', err.message);
        return err.message;
      });
  }

  async login(email: string, password: string) {
    this.firebaseAuth
      .auth
      .signInWithEmailAndPassword(email, password)
      .then(value => {
        const toast = this.toastCtrl.create({
          // showCloseButton: true,
          message: this.translate.get('304_REGISTRATION_HAS_BEEN_SUCCESSFULLY_COMPLETED'),
          duration: 3000,
          position: 'top'
        }).then((toast) => {
          toast.present();
        });



        // get user profile
        this.getUserProfile();

        this.onLoginSubject.next(this.user);

        this.dismiss();

      })
      .catch(err => {

        const toast = this.toastCtrl.create({
          // showCloseButton: true,
          message: err.message,
          duration: 3000,
          position: 'top'
        }).then((toast) => {
          toast.present();
        });

        console.log('Something went wrong:', err.message);
        return err;
      });
  }

  logout() {
    this.firebaseAuth.auth.signOut().then(() => {

      this.user = null;

      // restart user profile
      this.getUserProfile();

    });
  }

  async initPermission() {
    this.firebaseAuth.authState
      .pipe(
        map(user => {
          if (user) {
            this.afs.doc('users/' + user['uid']).get().pipe(
              map(profile => profile.data()),
              tap(data => {
                data ? this.hasPermission = (data['userType'] === 2 || data['userType'] === 3) : '';
              }),
            ).subscribe();
          }
        })
      )
      .subscribe();

  }
  // get user profile
  async getUserProfile() {
    if (!this.user) {
      this.userProfile = {};
      this.onLoginSubject.next(this.user);
      return false;
    }

    this.userProfile = (
      await this.afs.doc('users/' + this.user['uid']).get().toPromise()
    ).data() || {};

    this.hasPermission = (this.userProfile['userType'] === 2 || this.userProfile['userType'] === 3);


    if (this.userProfile['userType']) {
      this.userType = this.userProfile['userType'];
    }

    this.onLoginSubject.next(this.user);
  }
  dismiss() {
    this.modalController.dismiss();
  }

}
