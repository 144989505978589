import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewClientComponent } from '../../print/new-client/new-client.component';
import { NewClientModalComponent } from './new-client-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RoundButtonModule } from '../buttons/round-button/round-button.module';
import { DynamicFormModule } from '../buttons/dynamic-form/dynamic-form.module';
import { InputModule } from '../buttons/input/input.module';



@NgModule({
  declarations: [NewClientModalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    InputModule,
    IonicModule,
    FormsModule,
    RoundButtonModule,
    DynamicFormModule

  ],
  exports: [NewClientModalComponent]
})
export class NewClientModalModule { }
