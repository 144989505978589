import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as data from '../../assets/data/inputs/concat-details.json';
import { AuthService } from '../auth.service';
import { IAllCostumers, ICostumers } from '../interfaces/costumers';
import { IResponse } from '../interfaces/general';
@Injectable({
  providedIn: 'root'
})
export class CostumerService {
  constructor(private afs: AngularFirestore, private authService: AuthService) {
    this.getCostumers();

  }
  costumers: IAllCostumers = {};
  load$: any = new Subject();

  getContactInformation() {
    return data['default'];

  }
  deleteCostumer(id) {
    delete this.costumers[id]
    this.afs.collection('costumers').doc(id).delete();

  }
  async getCostumers() {
    if (!_.isEmpty(this.costumers)) {
      return;
    }
    this.afs
      .collection('costumers')
      .snapshotChanges()
      .pipe(
        map((actions: any) => {
          actions.map((a) => {
            const data: ICostumers = a.payload.doc.data();
            const id: string = a.payload.doc.id;
            this.costumers[id] = data;
          });
        })
      )
      .subscribe((costumers) => {
        this.load$.next();
      });
  }
  async addCostumer(properties: ICostumers) {

    try {
      // const result : any =  await this.authService.addMail(properties.concat.mail[0], properties.concat.phone, properties.name)
      // console.log("result",result);
    } catch (error) {
      
    }

    const costumer = await this.afs.collection('costumers').add(properties);
    this.costumers[(costumer as any)] = properties;

  }
  async saveCostumer() {
    await this.afs.doc('costumers').update(this.costumers);
  }
}
