import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import * as _ from 'lodash';
import { TranslateProvider } from '../../../providers';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
})
export class DynamicTableComponent implements OnInit {
  @Input() form = [];
  formControls: FormGroup[] = [];
  @Input() columns;
  @Input() columnsTypes ={};
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  constructor(private modalController: ModalController, private translate: TranslateProvider) { }

  ngOnInit() {
    this.form.forEach((line, index) => {
      const inputs = {};
      this.columns.forEach((column, columnIndex) => {
        inputs[column.key] = new FormControl(line[column.key]);
      })
      this.formControls.push(new FormGroup(inputs));
    })

    this.columns.forEach((column, columnIndex) => {
      if (!this.columnsTypes[column.key]) {
        this.columnsTypes[column.key] = column.type;
      }
    })
  }

  addButton() {
    const inputs = {};
    this.columns.forEach((column, columnIndex) => {
      inputs[column.key] = new FormControl('');
    })
    this.formControls.push(new FormGroup(inputs));
    // this.columns.map(_ => formArray.push(new FormControl('')))
  }

  save() {
    let data: any = [];

    this.formControls.forEach((line, index) => {
      const dataLine = {};
      this.columns.forEach((column, columnIndex) => {
        dataLine[column.key] = line.value[column.key];
      })
      data[index] = dataLine;
    })

    // this.form = _.mapValues(Object.assign(Object.keys(this.form), Object.values(this.form).sort((a, b) => { return a[0].value - b[0].value })));

    this.onSave.emit(data)

  }
  async delete(index) {
    this.formControls.splice(index, 1)
    this.save()
  }
}
