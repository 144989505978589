import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StepsService } from '../../services/steps.service';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth.service';
import * as firebase from 'firebase';
import { LanguageService } from '../../services/language.service';
@Component({
  selector: 'app-home-print',
  templateUrl: './home-print.page.html',
  styleUrls: ['./home-print.page.scss'],
})
export class HomePrintPage implements OnInit, AfterViewChecked {
  user: string;
  public object: Object = Object;
  activeType = 'product';
  activeProduct: string | null;
  orderProductKey: string | null;
  designType = 'custom';
  show = false as any; // add one more property
  statusPrintingsHouses: string = environment.statusPrintingsHouses;
  constructor(
    public dataService: DataService,
    private route: ActivatedRoute,
    public authService: AuthService,
    public steps: StepsService,
    public languageService: LanguageService,
    private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.orderProductKey = this.route.snapshot.paramMap.get('orderProductKey');
    // get active
    const obj = this.dataService.cart.designs;
    if (obj) {
      this.activeProduct = Object.keys(obj)[Object.keys(obj).length - 1];
    }
    if (firebase.auth() && firebase.auth().currentUser) {
      this.user = firebase.auth().currentUser.uid;
    }
  }

  ngAfterViewChecked() {
    const show = this.isShowExpand();
    if (show !== this.show) { // check if it change, tell CD update view
      this.show = show;
      this.cdRef.detectChanges();
    }

  }
  equalStep(index, value) {
    return this.steps.getStep(index) === value;
  }

  isShowExpand() {
    return this.steps.getCurrentStep();
  }


  openStep(i) {
    return localStorage.getItem('stepsPrint' + i.toString()) === 'true';
  }

}
