import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { DesignsByFilterComponent } from './designs-by-filter/designs-by-filter.component';
import { RouterModule } from '@angular/router';
import { FileInputComponent } from './inputs/file-input/file-input.component';
import { SizeCountsComponent } from './size-counts/size-counts.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SelectClientComponent } from './inputs/select-client/select-client.component';
import { OrderFileComponent } from './order-file/order-file.component';
import { FileInfoComponent } from './file-info/file-info.component';
import { OrderFilesComponent } from './order-files/order-files.component';
import { OrderComponent } from './order/order.component';
import { ShippingComponent } from './shipping/shipping.component';
import { OrderDetailsModule } from './order-details/order-details.module';
import { PipesModule } from '../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { MagicParamsComponent } from './magic-params/magic-params.component';
import { DynamicInputComponent } from './inputs/dynamic-input/dynamic-input.component';
import { PaperPricesComponent } from './inputs/paper-prices/paper-prices.component';
import { PaperSizeComponent } from './inputs/paper-size/paper-size.component';
import { ArtimeMenuComponent } from './artime-menu/artime-menu.component';
import { MessageModule } from './buttons/message/message.module';
import { MessagePipe } from './size-counts/message.pipe';
import { NewClientModalModule } from './new-client-modal/new-client-modal.module';
import { NewClientModalComponent } from './new-client-modal/new-client-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MessageModule,
        NewClientModalModule,
        TranslateModule,
        IonicModule,
        RouterModule,
        AutocompleteLibModule,
        OrderDetailsModule,
        PipesModule,
    ],
    declarations: [
        MessagePipe,
        DesignsByFilterComponent,
        FileInputComponent,
        SizeCountsComponent,
        SelectClientComponent,
        OrderFileComponent,
        FileInfoComponent,
        OrderFilesComponent,
        OrderComponent,
        ShippingComponent,
        MagicParamsComponent,
        DynamicInputComponent,
        PaperPricesComponent,
        PaperSizeComponent,
        ArtimeMenuComponent,
    ],
    exports: [
        MessagePipe,
        DesignsByFilterComponent,
        FileInputComponent,
        SizeCountsComponent,
        SelectClientComponent,
        OrderFileComponent,
        AutocompleteLibModule,
        FileInfoComponent,
        OrderFilesComponent,
        OrderComponent,
        ShippingComponent,
        MagicParamsComponent,
        DynamicInputComponent,
        PaperPricesComponent,
        PaperSizeComponent,
        ArtimeMenuComponent,
    ],
    entryComponents: [
        SelectClientComponent,
        DynamicInputComponent,
        NewClientModalComponent,
        PaperPricesComponent
    ],
})
export class ComponentsModule {
}
