import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DataService } from '../../data.service';
import { StepsService } from '../../services/steps.service';
@Component({
  selector: 'app-sub-menu-template',
  templateUrl: './sub-menu-template.component.html',
  styleUrls: ['./sub-menu-template.component.scss'],
})
export class SubMenuTemplateComponent implements OnInit {
  activeProduct: string;
  trash: string = environment.trash;
  pencil: string = environment.pencil;
  @Input() step;
  constructor(public dataService: DataService, private route: ActivatedRoute, private router: Router, private steps: StepsService) { }

  ngOnInit() {
    const obj = this.dataService.cart.designs;
    if (obj) {
      this.activeProduct = Object.keys(obj)[Object.keys(obj).length - 1];
    }
  }

  getStep(i) {
    return this.steps.getStep(i);
  }
  getCurrentStep() {
    return this.steps.getCurrentStep();
  }

}
