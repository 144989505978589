import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundButtonComponent } from './round-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [RoundButtonComponent],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule
  ],
  exports: [RoundButtonComponent]
})
export class RoundButtonModule { }
