import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicMoreInfoAlert, IMagicsInput, IMagicsParams } from '../interfaces/magics';
import { MainMagics } from './mainMagics';
import * as _ from 'lodash';
import { MagicsService } from '../services/magics.service';
import { ICartDesign, ICartTemplate } from '../interfaces/cart';
import { SettingService } from '../services/setting.service';
import { IProductInput } from '../interfaces/products';
import { IPaperPrice } from '../interfaces/setting';
import { TranslateProvider } from '../providers';
import { DataService } from '../data.service';
export class PaperMagic extends MainMagics {
    data: any = {};

    constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
        super(magicsService, dataService);
    }

    async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput): Promise<IMagicMoreInfo> {
        let objectCopies = this.magicsService.getNumberOfPages(design.size.width, design.size.height, template.print.copies, template)
        let copies = _.get(objectCopies, ["copies"], 0);
        let points: number = 0;
        let basic: IMagicMessageLabelDescription[] = [];
        const alerts: IMagicMoreInfoAlert[] = [];
        let singlePagePrice = 0;
        if (objectCopies) {
            singlePagePrice = this.magicsService.getPaperPriceByExtensionPrint(template, design);
        }
        const price = singlePagePrice * copies;

        basic.push({
            label: this.translate.get("551_A_SINGLE_PAPER_PRICE"),
            description: singlePagePrice
        })
        basic.push({
            label: this.translate.get("58_COPIES"),
            description: copies
        })
        return {
            basic,
            data: {},
            price,
            pointsNumber: points,
            alerts,
        };
    }


    isHiddenInput(): boolean {
        return false;
    }



}
