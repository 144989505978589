import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getImage'
})
export class GetImagePipe implements PipeTransform {

  transform(files, index): String {
    return files[index]['storageURL'];
  }

}
