import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'threePoints'
})
export class ThreePointsPipe implements PipeTransform {

  transform(str: string, len: number): string {
    if (!len) {
      len = 20;
    }

    if (!str || !str.length) {
      return str;
    }
    return str.length > 20 ? str.slice(0, len).concat('...') : str;

  }

}
