import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference } from '@angular/fire/firestore';
import { AuthService } from '../../auth.service';
import { DataService } from '../../data.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  public orders: any = [];
  public users: any = [];
  public filterUser$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  public filterStatus$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  public filterStatus: any = { id: '', name: '' };
  public ordersLimit$: BehaviorSubject<number | null> = new BehaviorSubject(20);
  public activeClient: any = { id: '', search: '' };
  public statusesArray: any = [];
  public statuses: any = {
    '2': this.translate.instant('585_WAITING_FOR_PAYMENT'),
    '3': this.translate.instant('586_PAID_UP'),
    '20': this.translate.instant('587_PAID_AND_DIED_FOR_PRINTING'),
    '30': this.translate.instant('588_WAS_PRINTED_WAITING_FOR_DELIVERY'),
    '40': this.translate.instant('589_WAS_PRINTED_WAITING_FOR_COLLECTION'),
    '50': this.translate.instant('590_PRINTED_AND_SENT'),
    '60': this.translate.instant('591_CUSTOMER_HAS_COLLECTED'),
  };



  constructor(
    private translate: TranslateService,
    private afs: AngularFirestore,
    public AuthService: AuthService,
    public dataService: DataService,
  ) {
    this.AuthService.onLogin$.subscribe((user) => {
      // if Connected user
      if (user) {
        // get my orders
        this.getData();
      }

    });

    if (!this.AuthService.user) {
      return;
    }

    // get orders
    this.getData();

  }

  getData() {

    if (this.AuthService.userType != 2 && this.AuthService.userType != 3) {
      return;
    }

    // get orders
    this.getOrders();

  }

  // get orders
  getOrders() {

    if (this.orders.length) {
      return;
    }

    combineLatest(
      this.filterUser$, this.filterStatus$, this.ordersLimit$
    ).pipe(
      switchMap(([user, status, ordersLimit]) =>
        this.afs.collection('orders', (ref: any) => {
          if (user) {
            ref = ref.where('userCreated', '==', user);
          }
          if (status) {
            ref = ref.where('status', '==', status);
          }
          ref = ref.orderBy('time', 'desc');

          return ref.limit(ordersLimit);
        }).snapshotChanges()
      ),
      map((actions: any) => {
        return actions.map((a) => {
          const data: Object = a.payload.doc.data();
          const id = a.payload.doc.id;
          const clientName = this.dataService.getClientName(data['userCreated']);
          return { id, clientName, ...data };
        });
      })
    ).subscribe((orders) => {
      this.orders = orders;
      this.buildStatusesArray();
    });
  }


  changeFilterStatus($event) {
    this.filterStatus$.next($event ? $event.id : null);
    this.filterStatus = new Object($event);
  }


  readMore() {

    const currentLimit = this.ordersLimit$.getValue();
    this.ordersLimit$.next(currentLimit + 20);
  }

  buildStatusesArray() {
    const statusesArray = [];
    for (const status in this.statuses) {
      statusesArray.push({
        id: parseInt(status),
        name: this.statuses[status],
      });
    }
    this.statusesArray = statusesArray;
  }

  getNameStatus(idStatus) {
    return this.statuses[idStatus];
  }

}
