import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isPrivateSize'
})
export class IsPrivateSizePipe implements PipeTransform {
  transform(size): any {
    return size.pricingBy !== 'customSize';
  }

}
