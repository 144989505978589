import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form.component';
import { FormLineModule } from '../form-line/form-line.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [DynamicFormComponent],
  imports: [
    CommonModule,
    FormLineModule,
    TranslateModule
  ],
  exports: [DynamicFormComponent]
})
export class DynamicFormModule { }
