import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { IPaperPricesValue } from '../../../interfaces/paper';

import { PaperService } from '../../../services/paper.service';
@Component({
  selector: 'app-paper-prices',
  templateUrl: './paper-prices.component.html',
  styleUrls: ['./paper-prices.component.scss'],
})
export class PaperPricesComponent implements OnInit , AfterContentInit {

  @Input() value: IPaperPricesValue[] = [];
  @Output() valueChange: EventEmitter<Function> = new EventEmitter();

  constructor(public paperService: PaperService) { }

  ngOnInit() {}

  submitValue() {
    this.valueChange.emit(this.value as any);
  }


  ngAfterContentInit() {
  }

  deleteRow(index) {
    this.value.splice(index, 1);
    this.submitValue();
  }

  addSizePrice() {
    // let sizeNotInList = 'A4';


    // for (const key in this.paperService.list) {
    //   const index = _.findIndex(this.value, {size: key});
    //   if (index == -1) {
    //     sizeNotInList = key;
    //     break;
    //   }
    // }
    // this.value.push({
    //   size : sizeNotInList,
    //   price : 0
    // });
  }


}
