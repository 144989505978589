import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { IDynamicInput } from '../../../interfaces/general';
import * as _ from 'lodash';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() input: IDynamicInput;
  @Input() value: any;
  @Input() border: boolean = false;
  @Input() after: string = "";
  @Input() parameter: any;
  @Input() flex: boolean = false;
  @Output() valueChange: EventEmitter<Function> = new EventEmitter();

  constructor() { }

  submitValue() {
    if (this.input && this.input.options && this.input.options.length > 0) {
      let keyToFilter: any = (this.input.options[0] as any).key
      if (keyToFilter) {
        let sorted = [];
        let data = { ...this.value }
        Object.keys(data).sort(function (a, b) {
          return data[a][keyToFilter] - data[b][keyToFilter];
        }).forEach(function (key) {
          sorted.push(data[key]);
        });
        if (sorted)
          this.value = sorted
      }
    }

    this.valueChange.emit(this.value);
  }

  ngAfterViewInit() {
    if (this.value === undefined && this.input.defaultValue !== undefined) {
      this.value = this.input.defaultValue;
      setTimeout(() => { this.submitValue(); }, 0);
    }
  }


  ngOnInit() {
  }

}
