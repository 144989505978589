import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicsInput, IMagicsParams } from '../interfaces/magics';
import { MainMagics } from './mainMagics';
import * as _ from 'lodash';
import { MagicsService } from '../services/magics.service';
import { ICartDesign, ICartTemplate } from '../interfaces/cart';
import { SettingService } from '../services/setting.service';
import { IProductInput } from '../interfaces/products';
import { TranslateProvider } from '../providers';
import { DataService } from '../data.service';


export class Creasing extends MainMagics {
    data: any = {};
    calculatorInputs: IMagicsInput[] = [
        {
            name: "machineID",
            type: "select",
            label: [{ text: this.translate.get("371_MACHINE") }],
            required: false,
            options: [],
            filter: [
                {
                    key: "parameter",
                    value: "60_creasing",
                },
            ],
        },
        {
            name: "amountCreasing",
            type: "select",
            label: [{ text: this.translate.get("674_THE_AMOUNT_OF_CREASING_IN_THE_UNIT") }],
            required: false,
            options: [{
                label: `0 ${this.translate.get("675_CREASING")}`,
                value: "0",
            },
            {
                label: `1 ${this.translate.get("60_creasing")}`,
                value: "1",
            },
            {
                label: `2 ${this.translate.get("675_CREASING")}`,
                value: "2",
            },
            {
                label: `3 ${this.translate.get("675_CREASING")}`,
                value: "3",
            },
            {
                label: `4 ${this.translate.get("675_CREASING")}`,
                value: "4",
            },
            {
                label: `5 ${this.translate.get("675_CREASING")}`,
                value: "5",
            }
            ],
            defaultValue: "0"
        }
    ];

    constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
        super(magicsService, dataService);
    }


    getPrice(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, machinesKey?: string): number {
        const minimumPrice = _.get(magicData, 'minimumPrice', 0);
        const pricePerMeter = _.get(magicData, 'pricePerMeter', 0);
        const cuttingMeter = this.getCuttingMeter(design, template);
        const max = _.max([parseFloat(minimumPrice), (cuttingMeter * pricePerMeter)]);
        return this.settingService.machines[machinesKey] && this.settingService.machines[machinesKey].parameters.print ? max + (cuttingMeter * this.settingService.machines[machinesKey].pulsePrice) : max;
    }

    async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput): Promise<IMagicMoreInfo> {

        const machinesPrice = this.magicsService.getMagicAllPrices('creasing', magicData, parameterPrice, count, design, template);

        const extensionCreasing = this.getExtensionByName(template, "creasing");
        const machineID = this.magicsService.getMachineID(template,design);
        const amountCreasing = _.get(extensionCreasing, "input.values.amountCreasing", null);
        const printPoints = _.get(this.settingService.machines, [machineID, "parameters", "60_creasing", "printPoints"], 1);
        const minPoints = _.get(this.settingService.machines, [machineID, "parameters", "60_creasing", "minimumPoints"], 0);
        let objectCopies = this.magicsService.getNumberOfPages(design.size.width, design.size.height, template.print.copies, template)
        let copies = _.get(objectCopies, ["copies"], 0);
        let x = _.get(objectCopies, ["x"], 0);

        let calcPoints = amountCreasing * copies * x
        let basic: IMagicMessageLabelDescription[] = [];


        let points = 0;
        let sortPrintPoints = _.sortBy(printPoints, 'amountOfCreasing');
        for (let i = 0; i < sortPrintPoints.length; i++) {
            if (sortPrintPoints[i].amountOfCreasing <= copies) {
                points = sortPrintPoints[i].pointsAmount
            }
            else {
                break;
            }
        }

        let pointsNumber = calcPoints * points

        if (pointsNumber > minPoints) {
            basic.push({
                label: `${this.translate.get("674_THE_AMOUNT_OF_CREASING_IN_THE_UNIT")}`,
                description: calcPoints,
            });
            basic.push({
                label: `${this.translate.get("547_PRICE_PER_POINT")}`,
                description: points,
            });
        }
        else {
            basic.push({
                label: `${this.translate.get("641_MINIMUM_POINTS")}`,
                description: minPoints,
            });
            if (amountCreasing != "0")
                pointsNumber = minPoints
        }


        return {
            basic: basic,
            machinesPrice,
            data: {},
            pointsNumber: pointsNumber
        };
    }


    isHiddenInput(): boolean {
        return false;
    }


    getCuttingMeter(design: ICartDesign, template: ICartTemplate): number {
        const copies = _.get(template, ['print', 'copies'], 0);
        const width = _.get(design, ['size', 'width'], 0) / 1000;
        const height = _.get(design, ['size', 'height'], 0) / 1000;
        return +(((width * 2) + (height * 2)) * copies).toFixed(2);
    }

}
