import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VatService {

  constructor() {

  }

  getPriceWithVat(price: number): number {
    return price / 100 * (100 + environment.vat);
  }
  getVat(price: number): number {
    return price / 100 * environment.vat;
  }
}
