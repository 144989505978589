import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { DataService } from '../../../data.service';
import { environment } from '../../../../environments/environment';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileInputComponent),
  multi: true
};


@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]

})
export class FileInputComponent implements ControlValueAccessor {
  environment: any = environment;
  // public @Input() folder : string = "general";
  @Input() public folder = 'general';
  @Input() public labelText: string | null;
  @Input() public path = true;
  public innerValue: any = '';
  public progress = 0;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor(public dataService: DataService) {
  }

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  // on select image
  async updateImage(e) {

    if (!e || !e.target.files || e.target.files.length === 0) {
      return false;
    }

    const upload: any = this.dataService.saveImage(e.target.files[0], this.folder);

    upload.subscribe((res = {}) => {
      switch (res['type']) {
        case 'done':
          this.innerValue = this.path ? res['data'].url : res['data'].fileName;
          console.log("--this.innerValue",this.innerValue);
          
          this.onChangeCallback(this.innerValue);
          break;
        case 'progress':
          this.progress = res['data'].progress;
          break;
        case 'error':
          console.log('error');
          break;

        default:
          break;
      }

    });

  }

}
