import { Component, Input, Output, OnInit } from '@angular/core';
import { IMagicsParams } from '../../interfaces/magics';
import { MagicsService } from '../../services/magics.service';

@Component({
  selector: 'app-magic-params',
  templateUrl: './magic-params.component.html',
  styleUrls: ['./magic-params.component.scss'],
})
export class MagicParamsComponent implements OnInit {
  @Input() magicKey: string;
  @Input() @Output() magicParams: IMagicsParams;

  constructor(public magicsService: MagicsService) {


   }

  ngOnInit() {

   }

}
