import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'getTime'
})
export class GetTimePipe implements PipeTransform {

  transform(time: any): any {
    if(!time){
      return '';
    }
    const dateTime = typeof time === 'string' ? moment(time, 'DD/MM/YYYY HH:mm').toDate() : time.toDate();
    return dateTime;
  }

}
