export const environment = {
  'firebaseConfig': {

    // /kol-graph
    "apiKey": "AIzaSyBmXEBt_Ky-kJmHfKGWw4nIv1HOn_HtUlI",
    "authDomain": "kol-graph.firebaseapp.com",
    "projectId": "kol-graph",
    "storageBucket": "kol-graph.appspot.com",
    "messagingSenderId": "175196698925",
    "appId": "1:175196698925:web:72b392f793553443db7ba4",
    "measurementId": "G-E0KNL5615B",
    "databaseURL": 'https://kol-graph.firebaseio.com',
    'firebaseStorageUrl': 'https://firebasestorage.googleapis.com/v0/b/kol-graph.appspot.com/o',


    //old-city-press
    // "apiKey": "AIzaSyCqYIMvrBfD7mFEAuMcR2a3_d_W_gTmp_s",
    // "authDomain": "old-city-press.firebaseapp.com",
    // "projectId": "old-city-press",
    // "storageBucket": "old-city-press.appspot.com",
    // "messagingSenderId": "1057329257686",
    // "appId": "1:1057329257686:web:4961c8c12c97176cb1fd77",
    // "measurementId": "G-58LPVF24EN",
    // "databaseURL": 'https://old-city-press.firebaseio.com',


    //demo1-5f824
    // apiKey: 'AIzaSyCZiAmWhqS8iRW3oydBamhmfFHLkyDQYQo',
    // authDomain: 'demo1-5f824.firebaseapp.com',
    // databaseURL: 'https://demo1-5f824.firebaseio.com',
    // projectId: 'demo1-5f824',
    // storageBucket: 'demo1-5f824.appspot.com',
    // messagingSenderId: '192410620012',
    // appId: '1:192410620012:web:fed607bf43bfb2f9f68849'
  },

 
    // 'firebase_url': 'https://us-central1-demo1-5f824.cloudfunctions.net/artimeStageApi',

  // /kol-graph
  'firebaseStorageUrl': 'https://firebasestorage.googleapis.com/v0/b/kol-graph.appspot.com/o',
  'firebase_url': 'https://europe-west2-kol-graph.cloudfunctions.net/api',

    //old-city-press
  // 'firebaseStorageUrl': 'https://firebasestorage.googleapis.com/v0/b/old-city-press.appspot.com/o',
  // 'firebase_url': 'https://us-central1-old-city-press.cloudfunctions.net/api',

   //demo1-5f824
  // 'firebase_url': 'http://localhost:3000',
  // 'firebaseStorageUrl': 'https://firebasestorage.googleapis.com/v0/b/demo1-5f824.appspot.com/o',

  system: 'print',
  vat: 17,
  version: 2,
  production: false, // set TRUE before you build and release a prod version.
  // Set your app configurations here.
  // For the list of config options, please refer to https://ionicframework.com/docs/api/config/Config/
  config: {
    iconMode: 'md',
    // mode: 'md',
    // iconMode: 'ios',
    // mode: 'ios'
    // preloadModules: true,
    // scrollPadding: false,
    // scrollAssist: true,
    autoFocusAssist: false,
    menuType: 'overlay'
  },
  // Set language to use.
  language: 'he',
  // Loading Configuration.
  // Please refer to the official Loading documentation here: https://ionicframework.com/docs/api/components/loading/LoadingController/
  loading: {
    spinner: 'circles'
  },
  // Toast Configuration.
  // Please refer to the official Toast documentation here: https://ionicframework.com/docs/api/components/toast/ToastController/
  toast: {
    position: 'bottom' // Position of Toast, top, middle, or bottom.
  },
  toastDuration: 3000, // Duration (in milliseconds) of how long toast messages should show before they are hidden.
  homePage: 'home',
  userType: 0,
  questionsPath: 'assets/data/questions.json',
  questionsImgPath: 'assets/images/questions/questions.png',
  polygonIconPath: 'assets/images/questions/Polygon 4.svg',
  inputsPath: 'assets/data/inputs.json',
  firstPrintIcon: 'assets/images/print/icon.svg',
  manIcon: 'assets/images/print/man-icon.svg',
  pinkSquare: 'assets/images/print/pinkSquare.svg',
  blueSquare: 'assets/images/print/blueSquare.svg',
  papers: 'assets/images/print/papers.svg',
  manIconBlue: 'assets/images/print/man-icon-blue.svg',
  iconSuccess: 'assets/images/print/success.svg',
  iconSuccess2: 'assets/images/print/success2.svg',
  pinkTrash: 'assets/images/print/pinkTrash.svg',
  printingsHouses: 'HUCxrMxJFx5tq4juBMN3',
  statusPrintingsHouses: 'bid',
  paperBlack: 'assets/images/print/paperBlack.svg',
  trashBlack: 'assets/images/print/trashBlack.svg',
  book: 'assets/calculator/book.svg',
  blueBook: 'assets/calculator/blueBook.svg',
  person: 'assets/images/print/person.svg',
  pencil: 'assets/calculator/pencil.svg',
  theCalculator: 'assets/calculator/theCalculator.svg',
  pinkPencil: 'assets/calculator/pink-pencil.svg',
  bluePencil: 'assets/calculator/blue-pencil.svg',
  trash: 'assets/calculator/trash.svg',
  bigTrash: 'assets/calculator/big-trash.svg',
  redPerson: 'assets/calculator/red-person.svg',
  calculator: 'assets/calculator/calculator.svg',
  points: 'assets/calculator/points.svg',
  art: 'assets/calculator/art.svg',
  plus: 'assets/setting/plus.svg',
  download: 'assets/images/print/download.svg',
  eye: 'assets/images/print/eye.svg',
  blueEye: 'assets/calculator/blueEye.svg',
  blueDownLoad: 'assets/calculator/blueDownLoad.svg',
  whitePencil: 'assets/calculator/whitePencil.svg',
  whitePaper: 'assets/calculator/whitePaper.svg',
  arrow: 'assets/images/arrow.svg',
  whiteTrash: 'assets/calculator/whiteTrash.svg',
  bluePen: 'assets/calculator/bluePen.svg',
  blueTrash: 'assets/calculator/blueTrash.svg',
  bluePaper: 'assets/calculator/bluePaper.svg',
  noPermission: 'assets/img/no-permission.svg',
  star: 'assets/images/Star.svg',
  warning: 'assets/img/warning.svg',
  definition: 'assets/setting/definitions.svg',
  blueArrow: 'assets/setting/arrow.svg',
  up: 'assets/setting/up.svg',
  exclamationMark: 'assets/images/print/exclamationMark.svg',
  pinkExclamationMark: 'assets/images/print/pinkExclamationMark.svg'

};
