import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isFirst'
})
export class IsFirstPipe implements PipeTransform {

  transform(array, item): string {
    if (item.key === 0) {
      return 'first';
    }
    if (item.value && array[item.key - 1] && item.value.name.startsWith(array[item.key - 1].name.charAt(0))) {
      if (item.value.name.startsWith(array[+item.key + 1].name.charAt(0))) {
        return 'middle';
      }
      return 'last';
    }
    if (array[+item.key + 1] && item.value.name.startsWith(array[+item.key + 1].name.charAt(0))) {
      return 'firstFrom';
    }
    return 'first';
  }

}
