import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {

  @Input() phone: String = '';
  @Input() address: String = '';
  @Input() isShipping: String = '';
  @Input() payment: String = '';
  @Input() approvedPayment: String = '';
  @Input() date: String = '';
  @Input() nameCoupon: String = '';
  @Input() activeCoupon: String = '';
  @Input() note: String = '';
  flag: boolean;
  constructor() { }

  ngOnInit() {
    this.flag = !!this.phone && !!this.address && !!this.isShipping;
  }

}
