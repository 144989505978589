import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../environments/environment';

console.log('environment.system',environment.system);

let routes: Routes = [
  { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule) },
  { path: 'generator-studio', loadChildren: () => import('./pages/generator-studio/generator-studio.module').then(m => m.GeneratorStudioPageModule) },
  { path: 'category/:mainCatID/:catID', loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule) },
  { path: 'category/:mainCatID', loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule) },
  { path: 'designs', loadChildren: './pages/designs/designs.module#DesignsPageModule', data: { noReuse: true } },
  { path: 'designs/:designID', loadChildren: './pages/design/design.module#DesignPageModule', data: { noReuse: true } },
  { path: 'designs/:designID/:catID', loadChildren: './pages/design/design.module#DesignPageModule', data: { noReuse: true } },
  { path: 'categories', loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule) },
  { path: 'create-design', loadChildren: () => import('./pages/create-design/create-design.module').then(m => m.CreateDesignPageModule) },
  { path: 'text-contents', loadChildren: () => import('./pages/text-contents/text-contents.module').then(m => m.TextContentsPageModule) },
  { path: 'cart', loadChildren: () => import('./pages/print-cart/print-cart.module').then(m => m.PrintCartPageModule) },
  { path: 'payment', loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentPageModule) },
  { path: 'create-congratulatory', loadChildren: () => import('./pages/create-congratulatory/create-congratulatory.module').then(m => m.CreateCongratulatoryPageModule) },
  { path: 'my-files', loadChildren: () => import('./pages/my-files/my-files.module').then(m => m.MyFilesPageModule) },
  { path: 'home-print', loadChildren: () => import('./print/home-print/home-print.module').then(m => m.HomePrintPageModule) },
  { path: 'orders', loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule) },
  { path: 'orders/:orderID', loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule) },
  { path: 'manageProducts', loadChildren: () => import('./manager/products/products.module').then(m => m.ProductsPageModule) },
  { path: 'managePrintingHouses', loadChildren: () => import('./manager/printing-houses-settings/printing-houses-settings.module').then(m => m.PrintingHousesSettingsPageModule) },
  { path: 'print', loadChildren: () => import('./print/print-product-selection/print-product-selection.module').then(m => m.PrintProductSelectionPageModule) },
  { path: 'print/product', loadChildren: () => import('./print/print-product-selection/print-product-selection.module').then(m => m.PrintProductSelectionPageModule) },
  { path: 'print/product/CartIndex', loadChildren: () => import('./print/print-product-selection/print-product-selection.module').then(m => m.PrintProductSelectionPageModule) },
  { path: 'print/designs', loadChildren: () => import('./print/print-design-selection/print-design-selection.module').then(m => m.PrintDesignSelectionPageModule) },
  { path: 'print/designs/CartIndex', loadChildren: () => import('./print/print-design-selection/print-design-selection.module').then(m => m.PrintDesignSelectionPageModule) },
  { path: 'print/editing/CartIndex', loadChildren: () => import('./print/print-editing/print-editing.module').then(m => m.PrintEditingPageModule) },
  { path: 'print/cart', loadChildren: () => import('./pages/print-cart/print-cart.module').then(m => m.PrintCartPageModule) },
  { path: 'print/payment', loadChildren: () => import('./print/print-payment/print-payment.module').then(m => m.PrintPaymentPageModule) },
  { path: 'print/designs/category', loadChildren: () => import('./print/designs-category/designs-category.module').then(m => m.DesignsCategoryPageModule) },
  { path: 'print/designs/category/:categoryID', loadChildren: () => import('./print/designs-category/designs-category.module').then(m => m.DesignsCategoryPageModule) },
  { path: 'print/design/:designID', loadChildren: './pages/design/design.module#DesignPageModule', data: { noReuse: true } },
  { path: 'print/design/:designID/:catID', loadChildren: () => import('./pages/design/design.module').then(m => m.DesignPageModule), data: { noReuse: true } },
  { path: 'sizes', loadChildren: () => import('./manager/sizes/sizes.module').then(m => m.SizesPageModule) },
  { path: 'sizes/:sizeID', loadChildren: () => import('./manager/size/size.module').then(m => m.SizePageModule) },
  { path: 'print-cart', loadChildren: () => import('./pages/print-cart/print-cart.module').then(m => m.PrintCartPageModule) },
  { path: 'thanks', loadChildren: () => import('./pages/thanks/thanks.module').then(m => m.ThanksPageModule) },
  { path: 'thanks/:orderID', loadChildren: () => import('./pages/thanks/thanks.module').then(m => m.ThanksPageModule) },
  { path: 'clients', loadChildren: () => import('./manager/clients/clients.module').then(m => m.ClientsPageModule) },
  { path: 'clients/:clientID', loadChildren: () => import('./pages/client/client.module').then(m => m.ClientPageModule) },
  { path: 'questions', loadChildren: () => import('./pages/questions/questions.module').then(m => m.QuestionsModule) },
  { path: 'gallery', loadChildren: () => import('./pages/gallery/gallery.module').then(m => m.Gallery2Module) },
  // { path: 'no-permission', loadChildren: () => import('./pages/no-permission/no-permission.module').then(m => m.NoPermissionModule) },
];
if (environment.system === 'print') {
  routes = [
    { path: '', loadChildren: () => import('./print/first-print/first-print.module').then(m => m.FirstPrintModule) },
    { path: 'print', loadChildren: () => import('./print/home-print/home-print.module').then(m => m.HomePrintPageModule) },
    { path: 'design/:designID', loadChildren: './pages/design/design.module#DesignPageModule', data: { noReuse: true } },
    { path: 'design/:designID/:catID', loadChildren: './pages/design/design.module#DesignPageModule', data: { noReuse: true } },
    { path: 'print-cart', loadChildren: () => import('./pages/print-cart/print-cart.module').then(m => m.PrintCartPageModule) },
    { path: 'print/login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
    { path: 'success', loadChildren: () => import('./print/success/success.module').then(m => m.SuccessModule) },
    { path: 'success/:orderID', loadChildren: () => import('./print/success/success.module').then(m => m.SuccessModule) },
    // { path: 'no-permission', loadChildren: () => import('./pages/no-permission/no-permission.module').then(m => m.NoPermissionModule) },
  ];
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
