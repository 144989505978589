import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'someChild'
})
export class SomeChildPipe implements PipeTransform {
  transform(products): boolean {
    return products && products.filter(product => product.statusProduct === 'public' && product.sizes && ( product.sizes.filter(size => size.pricingBy !== 'customSize' && size.pricingBy !== 'customSize')).length > 0  ).length > 0;
  }

}
