import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-print-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  person = environment.person;
  @Input() isNextAndPrev: boolean;
  constructor() { }

  ngOnInit() { }

}
