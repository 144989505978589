import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavController, MenuController, ToastController, AlertController, LoadingController } from '@ionic/angular';
import { TranslateProvider } from '../../../providers';
import * as firebase from 'firebase';
import { AuthService } from '../../../auth.service';
import { ViewController } from '@ionic/core';

@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.page.html',
  styleUrls: ['./login-component.page.scss'],
})
export class LoginComponentPage implements OnInit {
  public onLoginForm: FormGroup;
  public onRegisterForm: FormGroup;
  public active: any = 'login';

  constructor(
    public navCtrl: NavController,
    public menuCtrl: MenuController,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    private translate: TranslateProvider,
    private formBuilder: FormBuilder,
    public AuthService: AuthService,
) { }

  ngOnInit() {

    this.onLoginForm = this.formBuilder.group({
      'email': [null, Validators.compose([
        Validators.required
      ])],
      'password': [null, Validators.compose([
        Validators.required
      ])]
    });


    this.onRegisterForm = this.formBuilder.group({
      'fullName': [null, Validators.compose([
        Validators.required
      ])],
      'email': [null, Validators.compose([
        Validators.required
      ])],
      'password': [null, Validators.compose([
        Validators.required
      ])]
    });

  }


  login() {
    const login = this.AuthService.login(this.onLoginForm.value.email, this.onLoginForm.value.password );
    // on login
   // this.dismissModal();

  }



  signup() {
    this.AuthService.signup(this.onRegisterForm.value.email, this.onRegisterForm.value.password, this.onRegisterForm.value.fullName);

    // on signup
  //  this.dismissModal();

  }

}
