import { Injectable } from '@angular/core';
import { SettingService } from './setting.service';
import * as _ from 'lodash';
import { IMagicsInput } from '../interfaces/magics';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root'
})
export class InputsService {

  constructor(private settingService: SettingService, private dataService: DataService) {
  }

  public getSelectMachineInput(input: IMagicsInput, extension, size) {
    let options = [];

    for (const machineID in this.settingService.machines) {
      const machine = this.settingService.machines[machineID];
      let machineTrue = true;
      if (input.filter) {
        for (let j = 0; j < input.filter.length; j++) {
          machineTrue = true
          switch (input.filter[j].key) {
            case 'parameter':
              if (!machine.parameters[input.filter[j].value]) {
                machineTrue = false;
              }
              break;

            default:
              break;
          }
          if (machineTrue)
            break;
        }
        if (machineTrue) {
          options.push({ value: machineID, label: machine.name });
        }
      }
    }
    let defaultValue;
    const defaultMachine = this.dataService.getDefaultValue('machineID', size)
    if (defaultMachine) {
      defaultValue = defaultMachine
    }
    else if (options.length > 0) {
      defaultValue = options[0].value
    }

    return {
      options,
      defaultValue,
    }
  }

  public getSelectSizePaperInput(machineID, size) {
    let options = [];
    let defaultValue;
    let sizes = this.settingService.paper.size
    let dis;
    let index = 0;
    if (machineID) {
      options = this.settingService.machines[machineID]
        .sizes.map((size) => {
          const label = this.settingService.getPaperSizeName(size);
          return { value: size, label };
        });

      const defaultSize = this.dataService.getDefaultValue('sizeID', size)
      if (defaultSize)
        defaultValue = parseInt(defaultSize)
      else if (options.length > 0) {
        for (let i = 0; i < options.length; i++) {
          let option = sizes[options[i].value]
          if ((option.height >= size.height) &&
            (option.width >= size.width)) {
            const difference = (option.height - size.height) + (option.width - size.width)
            if (!dis || (difference < dis)) {
              dis = difference
              index = i
            }
          }
        }
        defaultValue = parseInt(options[index].value)
      }
    }

    return {
      options,
      defaultValue,
    }
  }

  public getSelectTypePaperInput(machineID, size) {
    let options = [];
    let defaultValue;
    let types = this.settingService.paper.type
    if (machineID) {
      options = this.settingService.machines[machineID]
        .types.map((type) => {
          return { value: type, label: this.settingService.getPaperTypeName(type) };
        });
      const defaultType = this.dataService.getDefaultValue('typeID', size)
      if (defaultType)
        defaultValue = defaultType

      else if (options.length > 0) {
        let index = 0
        for (let i = 0; i < options.length; i++) {
          if ((types[options[i].value].addPercentagePoints == undefined) || types[options[i].value].addPercentagePoints == 0) {
            index = i;
            break;
          }

        }
        defaultValue = options[index].value
      }
    }

    return {
      options,
      defaultValue,
    }
  }

  public getSelectThicknessPaperInput(machineID, size) {
    let options = [];
    let defaultValue;

    const thicknesseData = this.settingService.getThickness();

    if (machineID) {
      options = this.settingService.machines[machineID].thicknesses.map((thicknesse) => {
        return { value: thicknesse, label: thicknesseData[thicknesse] };
      });

      const defaultThicknes = this.dataService.getDefaultValue('thicknesID', size)
      if (defaultThicknes)
        defaultValue = parseInt(defaultThicknes)

      else if (options.length > 0) {
        defaultValue = parseInt(options[0].value)
      }
    }

    return {
      options,
      defaultValue,
    }
  }

  public getCheckboxColorfulInput(machineID, size) {
    let defaultValue;
    if (machineID) {
      const defaultColorful = this.dataService.getDefaultValue('colorful', size)
      if (defaultColorful)
        defaultValue = defaultColorful

      else {
        defaultValue = true
      }
    }

    return {
      defaultValue
    }
  }

  public getCheckboxTwoSidesInput(machineID, size) {
    let defaultValue;
    if (machineID) {
      const defaultTwoSides = this.dataService.getDefaultValue('twoSides', size)
      if (defaultTwoSides)
        defaultValue = defaultTwoSides

      else {
        defaultValue = false
      }
    }

    return {
      defaultValue
    }
  }
}
