import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { IMagicMessageLabelDescription } from "../../../interfaces/magics";

@Component({
    selector: "app-message",
    templateUrl: "./message.component.html",
    styleUrls: ["./message.component.scss"],
})
export class MessageComponent implements OnInit {
    @Input() text: string | IMagicMessageLabelDescription[] = "";
    constructor(private popoverController: PopoverController) {}

    ngOnInit() {      
        if (typeof this.text === "object") {
            let text = "<div>";
            this.text.forEach((item: IMagicMessageLabelDescription) => {
                text += `<div><span class="label" style=" font-weight: bold; " >${item.label}: </span class="description"><span>${item.description}</span></div>`;
            });
            text += "</div>";
            this.text = text;
        }
    }

    dismiss() {
        this.popoverController.dismiss();
    }
}
