import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../data.service';

@Pipe({
  name: 'file'
})
export class FilePipe implements PipeTransform {
constructor(private dataService: DataService) {}
  transform(id): any {
    return this.dataService.getFiles$(id);
  }

}
