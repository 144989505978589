import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IDynamicInput } from '../../interfaces/general';
import { TranslateProvider } from '../../providers';
import { CostumerService } from '../../services/costumer.service';

@Component({
  selector: 'app-new-client-modal',
  templateUrl: './new-client-modal.component.html',
  styleUrls: ['./new-client-modal.component.scss'],
})
export class NewClientModalComponent implements OnInit {
  inputs: IDynamicInput;
  mail: IDynamicInput;
  img: string = environment.plus;
  properties;
  constructor(private concatInformation: CostumerService, private translate: TranslateProvider) { }

  ngOnInit() {
    this.properties = {
      activity: true,
      name: '',
      id: '',
      dealerNumber: '',
      note: '',
      concat: {
        city: '',
        country: '',
        mail: [''],
        person: '',
        phone: '',
        street: '',
        telephone: ''
      }
    };
    this.inputs = this.concatInformation.getContactInformation();
    this.mail = {
      condition: false,
      defaultValue: '',
      label: [{ text: this.translate.get('390_MAIL') }],
      name: 'mail',
      placeholder: this.translate.get('400_TYPE_HERE'),
      type: 'text',
      value: 'mail'
    };
  }
  addMail() {
    this.properties.concat.mail.length < 3 && this.properties.concat.mail.push('');
  }
  save() {
    this.concatInformation.addCostumer(this.properties);
  }
}
