import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'charat'
})
export class CharAtPipe implements PipeTransform {
  transform(str: string): string {
    return str.charAt(0);
  }
}
