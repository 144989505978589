import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { TranslateProvider } from '../../providers';

@Pipe({
  name: 'message'
})
export class MessagePipe implements PipeTransform {
  constructor(private translate: TranslateProvider) {

  }
  transform(sizeCount): any {
    const ans = this.getPages(sizeCount);
    if (ans) {
      return '<div> <span>' + this.translate.get('401_ON_THE_LOGIN_PAGE') + '&nbsp;</span>' + ans.unitsPerPage + ' <span>' + this.translate.get('402_TOTAL_COPIES') + '&nbsp;</span>' + ans.copies / ans.unitsPerPage + '<span>&nbsp;' + this.translate.get('403_BEATS_SINGLE_PULSE_PRICE_TO_DELIVER') + '&nbsp;</span>' + ans.price + '<span>&nbsp;' + this.translate.get('404_AJ') + '</span>';
    }
    return '<div>' + this.translate.get('407_THERE_IS_NO_SUITABLE_MACHINE') + '</div>';
  }
  getPages(sizeCount) {
    let arr = [];
    const { width, height } = sizeCount.design.value.size;
    if (!width || !height) {
      return;
    }
    Object.values(sizeCount.paper.list).map(element => {
      arr.push({ size: (element as any).label, pages: (_.min([(element as any).width / width, (element as any).height / height])) });
    });
    arr = _.sortBy(arr, 'pages').map(({ size, pages }) => {
      if (sizeCount.printPrice.copies < pages) {
        return { size, pages, extra: parseFloat((pages - sizeCount.printPrice.copies).toFixed(2)) };
      } else {
        return { size, pages, extra: parseFloat((((pages % 1).toFixed(2) as any) * sizeCount.printPrice.copies / pages.toFixed(0)).toFixed(2)) };
      }
    });
    arr = _.sortBy(arr, 'extra');

    let count = 0, sort = [], check;
    arr.forEach(item => {
      check && check.extra !== item.extra && count++;
      !sort[count] ? sort[count] = [item] : sort[count].push(item);
      check = item;
    });
    let pages;
    count = 0;
    while (!pages) {
      pages = sizeCount.magicsService.getNumberOfPagesForBeats({ paperPrices: sort[count] }, sizeCount.design.value, sizeCount.design.value.templates[0]);
      count++;
      if (count > sort.length) {
        break;
      }
    }

    return pages || 0;


  }
}
