import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'counter'
})
export class CounterPipe implements PipeTransform {
  transform(i: number): any {
    return new Array(i);
  }
}
