import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateProvider } from './providers';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormsModule } from '@angular/forms';
import { LoginPage } from './pages/login/login.page';
import { LoginPageModule } from './pages/login/login.module';
import { LoginComponentPageModule } from './pages/login/login-component/login-component.module';
import { LoginComponentPage } from './pages/login/login-component/login-component.page';
import { AddColorComponent } from './components/add-color/add-color.component';
import { AddLayoutComponent } from './components/add-layout/add-layout.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { HomePrintPageModule } from './print/home-print/home-print.module';
import { HomePrintPage } from './print/home-print/home-print.page';
import { ComponentsModule } from './components/components.module';
import { DeleteModule } from './pages/delete/delete.module';
import { DeleteComponent } from './pages/delete/delete.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// get storage name
export function storageName() {
  return 'artime';
}


@NgModule({
  declarations: [AppComponent, AddColorComponent, AddLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(environment.config),
    AppRoutingModule,
    HttpClientModule,
    DeleteModule,
    IonicStorageModule.forRoot({
      name: storageName(),
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    LoginPageModule,
    LoginComponentPageModule,
    FormsModule,
    HomePrintPageModule,
    ComponentsModule,

  ],
  providers: [
    StatusBar,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TranslateProvider,
    AngularFireAuth,
    ScreenTrackingService,
    UserTrackingService,
    NgxImageCompressService,

  ],
  exports: [
    AddColorComponent
  ],
  bootstrap: [AppComponent],
  entryComponents: [LoginPage, LoginComponentPage, AddColorComponent, AddLayoutComponent, HomePrintPage, DeleteComponent],
})
export class AppModule { }
