import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
import { DataService } from '../../data.service';
import { FilesService } from '../../pages/my-files/files.service';
import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'order-file',
  templateUrl: './order-file.component.html',
  styleUrls: ['./order-file.component.scss'],
})
export class OrderFileComponent implements OnInit {
  @Input() public item: any = {};
  @Input() public styleFile = 'block';
  @Input() public showLabel = true;

  parseInt: any = parseInt;

  constructor(public dataService: DataService, public settingService: SettingService, public FilesService: FilesService, public AuthService: AuthService) { }

  ngOnInit() {
  }


}
