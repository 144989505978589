import { Component, Input, OnInit, HostBinding } from '@angular/core';
import { OrdersService } from '../../pages/orders/orders.service';
import { TranslateProvider } from '../../providers';
import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
  @Input() item: any = {};
  @HostBinding('class.open') isOpenOrder = false;
  isPrint: Boolean;
  constructor(public ordersService: OrdersService, private translate: TranslateProvider, public settingService: SettingService) { }

  ngOnInit() {
    this.isPrint = this.ordersService.getNameStatus(this.item.status) === this.translate.get('565_WAITING_FOR_PRINTING');
  }

  toggleInfo(item) {
    item.showMore = !item.showMore;
    this.isOpenOrder = item.showMore;
  }
}
