import { Injectable } from '@angular/core';
import { paperSizes } from '../helpers/common_paper_sizes';
import { IPaper, IPaperItem } from '../interfaces/paper';
@Injectable({
  providedIn: 'root'
})
export class PaperService {

  constructor() {
  }




}
