import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../../../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicTableModule } from '../../inputs/dynamic-table/dynamic-table.module';



@NgModule({
  declarations: [InputComponent],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    FormsModule,
    TranslateModule,
    DynamicTableModule
  ],
  exports: [InputComponent]
})
export class InputModule { }
