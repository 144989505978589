import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
    selector: 'app-designs-by-filter',
    templateUrl: './designs-by-filter.component.html',
    styleUrls: ['./designs-by-filter.component.scss'],
})
export class DesignsByFilterComponent implements OnInit {
    designs: any = [];
    @Input() filterBy = 'sizeID';
    @Input() filterValue: string | null;

    constructor(public dataService: DataService) { }

    ngOnInit() {
        // on load data
        this.dataService.designsLoad$.subscribe(() => {
            this.getDesigns();
        });
        this.getDesigns();
    }

    getDesigns() {
        if (this.filterBy && this.filterValue) {
            // get designs
            this.designs = this.dataService.designs.filter(
                (design) => this.filterBy === 'categories' ? design[this.filterBy].indexOf(this.filterValue) > -1 : design[this.filterBy] === this.filterValue
            );
        }
    }
}
