import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "../../data.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { PopoverController } from "@ionic/angular";
import { environment } from "../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-add-color",
    templateUrl: "./add-color.component.html",
    styleUrls: ["./add-color.component.scss"],
})
export class AddColorComponent implements OnInit {
    form: any = {
        name: null,
        backgroundUrl: null,
    };
    @Input() designID;
    @Input() colors;
    @Input() template;
    @Input() layout;
    environment: any = environment;

    constructor(
        public dataService: DataService,
        private afs: AngularFirestore,
        public popoverController: PopoverController,
        public translate: TranslateService
    ) {}

    ngOnInit() {}

    // on select image
    async updateImage(e, folder, path = true) {
        if (!e || !e.target.files || e.target.files.length === 0) {
            return false;
        }

        const file: any = await this.dataService.saveImage(
            e.target.files[0],
            folder
        );

        if (file) {
            const value = path ? file.url : file.fileName;

            this.form.backgroundUrl = value;
        }
    }

    dismissPopover() {
        this.popoverController.dismiss();
    }

    async createColor() {
        if (!this.form.name || !this.form.backgroundUrl) {
            alert(this.translate.get("3_NEW"));
            return;
        } else {
            // if update for tempalte
            if (this.template) {
                const newDoc = await this.afs
                    .collection(`designs/${this.designID}/designsColors`)
                    .doc(this.form.name)
                    .update({
                        [`t${this.template}${
                            this.layout > -1 ? "_" + this.layout : ""
                        }`]: this.form.backgroundUrl,
                    })
                    .then((newColor) => {
                        this.dismissPopover();
                    });
            } else {
                // if is a new color for main
                // let key = (
                //     Number(new TextEncoder().encode(this.form.name).join("")) /
                //     33
                // )
                //     .toString()
                //     .substr(-6)
                //     .replace(".", "");
                // create new design
                const newDoc = await this.afs
                    .collection(`designs/${this.designID}/designsColors`)
                    .add(this.form)
                    .then((newColor) => {
                        this.dismissPopover();
                    });
            }
        }
    }
}
