import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetValidValuePipe } from './get-valid-value.pipe';
import { GetColorPipe } from './get-color.pipe';
import { LenPipe } from './len.pipe';
import { GetTempPipe } from './get-temp.pipe';
import { LogPipe } from './log.pipe';
import { EqualStepPipe } from './equalStep.pipe';
import { AbsPipe } from './abs.pipe';
import { ExtensionsPipe } from './extensions.pipe';
import { FilePipe } from './file.pipe';
import { GetNamePipe } from './get-name.pipe';
import { SomeChildPipe } from './some-child.pipe';
import { GetTimePipe } from './get-time.pipe';
import { GetImagePipe } from './get-image.pipe';
import { IncludePipe } from './include.pipe';
import { IsPrivateSizePipe } from './is-private-size.pipe';
import { CounterPipe } from './counter.pipe';
import { ThreePointsPipe } from './three-points.pipe';
import { IsPrivatePipe } from './is-private.pipe';
import { GetLengthPipe } from './get-length.pipe';
import { SumPipe } from './sum.pipe';
import { IncludesPipe } from './includes.pipe';
import { IncludeParameterPipe } from './include-parameter.pipe';
import { JoinPipe } from './join.pipe';
import { CharAtPipe } from './charAt.pipe';
import { IsFirstPipe } from './is-first.pipe';
import { ExaminationPipe } from './examination.pipe';
import { GetDirectionPipe } from './get-direction.pipe';

@NgModule({
  declarations: [
    GetValidValuePipe,
    GetColorPipe,
    LenPipe,
    GetTempPipe,
    LogPipe,
    EqualStepPipe,
    ExtensionsPipe,
    AbsPipe,
    GetNamePipe,
    SomeChildPipe,
    FilePipe,
    GetTimePipe,
    GetImagePipe,
    IncludePipe,
    IsPrivateSizePipe,
    CounterPipe,
    ThreePointsPipe,
    IsPrivatePipe,
    GetLengthPipe,
    SumPipe,
    IncludesPipe,
    IncludeParameterPipe,
    JoinPipe,
    CharAtPipe,
    IsFirstPipe,
    ExaminationPipe,
    GetDirectionPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GetValidValuePipe,
    GetColorPipe,
    LenPipe,
    GetTempPipe,
    LogPipe,
    EqualStepPipe,
    ExtensionsPipe,
    AbsPipe,
    ExaminationPipe,
    GetNamePipe,
    SomeChildPipe,
    GetDirectionPipe,
    FilePipe,
    GetTimePipe,
    GetImagePipe,
    IncludePipe,
    IsPrivateSizePipe,
    CounterPipe,
    ThreePointsPipe,
    IsPrivatePipe,
    GetLengthPipe,
    SumPipe,
    IncludesPipe,
    IncludeParameterPipe,
    JoinPipe,
    CharAtPipe,
    IsFirstPipe
  ]

})
export class PipesModule { }
