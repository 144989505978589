import { ICartDesign, ICartTemplate, ITemplatePrintExtension } from '../interfaces/cart';
import { IMagicMoreInfo, IMagicsInput, IMagicsParams, IPaperOption } from '../interfaces/magics';
import { IProductInput } from '../interfaces/products';
import { MagicsService } from '../services/magics.service';
import * as _ from 'lodash';
import { ISize } from '../interfaces/sizes';
import { DataService } from '../data.service';

export class MainMagics {
    inputs: IMagicsInput[];
    calculatorInputs: IMagicsInput[] = [];
    public displayPricePerUnitInPriceList = false;
    public order: number = 999;
    constructor(public magicsService: MagicsService, public dataService: DataService) {
    }

    getPrice(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, machinesKey?: string): number {
        return 0;
    }

    async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, base: boolean = false): Promise<IMagicMoreInfo> {
        return {
            basic: '',
            machinesPrice: [],
            data: {},
            order: this.order
        };
    }

    isHiddenInput(): boolean {
        return false;
    }
    getNumberOfPages(magicData: IMagicsParams, width: number, height: number, copies: number, template: ICartTemplate, design?: ICartDesign): IPaperOption {
        return { copies: 0 };

    }

    getExtensionByName(template: ICartTemplate, name: string): IMagicsInput {
        let input;
        for (const extensionID in template.print.extensions) {
            if (template.print.extensions[extensionID].input.magicName === name) {
                input = template.print.extensions[extensionID];
            }
        }
        return input;
    }

    getCalculatorInputs(extension: ITemplatePrintExtension, size: ISize): IMagicsInput[] {
        let machineID = _.get(extension, 'input.values.machineID');

        return this.calculatorInputs.map(input => {
            switch (input.name) {
                case 'machineID':
                    const selectMachineInput: any = this.magicsService.inputsService.getSelectMachineInput(input, extension, size);
                    input.options = selectMachineInput.options;
                    if (!_.get(extension, 'input.values.machineID') && _.get(extension, 'input.magicName') == "print") {
                        input.defaultValue = selectMachineInput.defaultValue;
                        if (input.defaultValue)
                            machineID = input.defaultValue
                    }
                    break;
                case 'sizePaper':
                    const selectSizePaperInput: any = this.magicsService.inputsService.getSelectSizePaperInput(machineID, size);
                    input.options = selectSizePaperInput.options;
                    if (!_.get(extension, 'input.values.sizePaper') && _.get(extension, 'input.magicName') == "print") {
                        input.defaultValue = selectSizePaperInput.defaultValue;
                    }
                    break;
                case "typePaper":
                    const selectTypePaperInput: any = this.magicsService.inputsService.getSelectTypePaperInput(machineID, size);
                    input.options = selectTypePaperInput.options;

                    if (!_.get(extension, 'input.values.typePaper') && _.get(extension, 'input.magicName') == "print") {
                        input.defaultValue = selectTypePaperInput.defaultValue;
                    }
                    break;
                case "thickPaper":
                    const selectThicknessePaperInput: any = this.magicsService.inputsService.getSelectThicknessPaperInput(machineID, size);
                    input.options = selectThicknessePaperInput.options;
                    if (!_.get(extension, 'input.values.thickPaper') && _.get(extension, 'input.magicName') == "print") {
                        input.defaultValue = selectThicknessePaperInput.defaultValue;
                    }
                    break;

                case "twoSided":
                    const selectTwoSidesInput: any = this.magicsService.inputsService.getCheckboxTwoSidesInput(machineID, size);
                    if (!_.get(extension, 'input.values.twoSided') && _.get(extension, 'input.magicName') == "print") {
                        input.defaultValue = selectTwoSidesInput.defaultValue;
                    }
                    break;
                case "colorful":
                    const selectColorfulInput: any = this.magicsService.inputsService.getCheckboxColorfulInput(machineID, size);
                    if (!_.get(extension, 'input.values.colorful') && _.get(extension, 'input.magicName') == "print") {
                        input.defaultValue = selectColorfulInput.defaultValue;
                    }
                    break;
            }
            return input;
        });
    }
}
