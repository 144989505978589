import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit {
  @Input() inputs;
  @Input() parameter;
  @Input() flex;

  constructor() { }

  ngOnInit() {
  }

  isLine(arr) {
    return arr[0].input.condition === false || !!this.parameter.key[arr[0].input.condition];

  }
}
