import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicsInput, IMagicsParams } from "../interfaces/magics";
import { MainMagics } from "./mainMagics";
import * as _ from "lodash";
import { MagicsService } from "../services/magics.service";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { SettingService } from "../services/setting.service";
import { IProductInput } from "../interfaces/products";
import { TranslateProvider } from "../providers";
import { DataService } from "../data.service";

export class Cut extends MainMagics {
  data: any = {};
  calculatorInputs: IMagicsInput[] = [
    {
      name: "machineID",
      type: "select",
      label: [{ text: this.translate.get("371_MACHINE") }],
      required: false,
      options: [],
      filter: [
        {
          key: "parameter",
          value: "50_cut",
        },
      ],
    },
    {
      name: "type",
      type: "select",
      label: [],
      required: false,
      options: [
        {
          label: this.translate.get("645_ALL_DIRECTIONS"),
          value: "allSides",
        },
        {
          label: this.translate.get("646_AN_ADJACENT_PRINTING"),
          value: "oneSide",
        },
        {
          label: this.translate.get("647_WITHOUT_CUTTING"),
          value: "withoutCutting",
        },
      ],
      defaultValue: "allSides",
    },
  ];
  constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
    super(magicsService, dataService);
  }

  getPointsCut(machineID: string, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, typeCut?: string): any {
    let objectCopies = this.magicsService.getNumberOfPages(design.size.width, design.size.height, template.print.copies, template)
    let copies = _.get(objectCopies, ["copies"], 0);
    let x = _.get(objectCopies, ["x"], 0);
    let y = _.get(objectCopies, ["y"], 0);
    let xAmount: number = 0;
    let yAmount: number = 0;

    switch (typeCut) {
      case "allSides":
        xAmount = x * 2;
        yAmount = y * 2;
        break;
      case "oneSide":
        xAmount = x + 1;
        yAmount = y + 1;
        break;
      case "withoutCutting":
        xAmount = 0;
        yAmount = 0;
        break;
    }

    const thicknessID = this.magicsService.getPaperThick(template, design)
    let thickness = 100;
    if (thicknessID) {
      const thicknessData = this.settingService.getThicknessData(thicknessID);
      if (thicknessData) {
        thickness = thicknessData;
      }
    }

    const pointsPerCut = _.get(this.settingService.machines, [machineID, "parameters", "50_cut", "cuttingStraightLine"], 1);
    const minimumCutPoints = _.get(this.settingService.machines, [machineID, "parameters", "50_cut", "minimumCutPoints"], 0);

    const calc: any = this.calc(
      [
        { axis: "X", amount: xAmount },
        { axis: "Y", amount: yAmount },
      ],
      thickness,
      copies
    );
    const amountOfCuts = calc.total;

    const dataResponse = {
      x,
      y,
      xAmount,
      yAmount,
      amountOfCuts,
      pointsPerCut,
      ...calc,
    };

    let points: number = 0;

    if ((minimumCutPoints > pointsPerCut * amountOfCuts) && typeCut != "withoutCutting") {
      points = minimumCutPoints;
      dataResponse.minimumCutPoints = minimumCutPoints;
    } else {
      points = pointsPerCut * amountOfCuts;
    }

    dataResponse.points = points;

    return dataResponse;
  }

  async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput): Promise<IMagicMoreInfo> {
    const extensionCut = this.getExtensionByName(template, "cut");
    const machineID = this.magicsService.getMachineID(template,design);
    let points: number = 0;
    let amountOfCuts: number = 0;
    let pointsPerCut: number = 0;
    let basic: IMagicMessageLabelDescription[] = [];

    if (machineID) {
      const typeCut = _.get(extensionCut, "input.values.type", "allSides");
      const cutData = this.getPointsCut(machineID, parameterPrice, count, design, template, typeCut);

      if (cutData) {
        points = cutData.points;
        amountOfCuts = cutData.amountOfCuts;
        pointsPerCut = cutData.pointsPerCut;
        if(cutData.minimumCutPoints){
          basic.push({
            label: this.translate.get("648_CUTTING_STATION_MINIMUM_POINTS"),
            description: cutData.minimumCutPoints,
          });
        }
        basic.push({
          label: `X ${this.translate.get("643_ON_THE_PAGE")}`,
          description: cutData.xAmount,
        });

        basic.push({
          label: `${this.translate.get("644_CUTS_DIRECTION")} X`,
          description: cutData.cutX,
        });
        basic.push({
          label: `Y ${this.translate.get("643_ON_THE_PAGE")}`,
          description: cutData.yAmount,
        });

        basic.push({
          label: `${this.translate.get("644_CUTS_DIRECTION")} Y`,
          description: cutData.cutY,
        });
        basic.push({
          label: this.translate.get("126_PACKAGES"),
          description: cutData.packages,
        });
      }
    }

    basic.push({
      label: this.translate.get("573_CUTS"),
      description: amountOfCuts,
    });
    basic.push({
      label: this.translate.get("574_PONGS_FOR_CUTTING"),
      description: pointsPerCut,
    });

    return {
      basic,
      data: {
        amountOfCuts,
      },
      pointsNumber: points,
    };
  }

  isHiddenInput(): boolean {
    return false;
  }

  calc(cutsData, thickness: number = 0, copies: number = 0) {
    const totalThickness = thickness * copies;
    const naxThickness = 100 * 500;
    let response = { cutX: 0, cutY: 0 };
    let packages = _.max([Math.ceil(totalThickness / naxThickness), 1]);

    cutsData.forEach((cutData) => {
      let cuts = [];
      for (let i = 0; i < packages; i++) {
        let newThickness = totalThickness;
        let newItem = cutData.amount;
        let level = 1;
        let isContinue = true;
        let lastMedianR = cutData.amount;
        let lastMedianL = cutData.amount;

        let rounds = 0;
        while (isContinue) {
          rounds++;
          if (newThickness > naxThickness) {
            level = 1;
          }

          if (lastMedianR > 1 || (level === 1 && cutData.amount)) {
            const dataR = this.getRAndL(lastMedianR);
            lastMedianR = dataR.lastMedianR;
            newItem = newItem - level;
            cuts.push({
              level,
              dataR,
              newItem,
              axis: cutData.axis,
            });
            ++level;
            newThickness = totalThickness * level;
          }

          if (lastMedianL > 1 || (level === 1 && cutData.amount)) {
            const dataL = this.getRAndL(lastMedianL);
            lastMedianL = dataL.lastMedianL;
            newItem = newItem - level;
            cuts.push({
              level,
              dataL,
              newItem,
              axis: cutData.axis,
            });
            ++level;
            newThickness = totalThickness * level;
          }
          if (rounds > 50 || newItem < 1) {
            isContinue = false;
          }
        }
      }

      response[`cut${cutData.axis}`] = cuts.length;
    });

    response["total"] = response["cutX"] + response["cutY"];
    response["packages"] = packages;

    return response;
  }

  getRAndL(lastMedian) {
    const r = this.getMedian(lastMedian);
    const l = this.getMedian(lastMedian);
    const lastMedianR = r;
    const lastMedianL = l;

    return {
      r,
      l,
      lastMedianR,
      lastMedianL,
    };
  }

  getMedian(x: number) {
    const median = Math.ceil(x / 2);
    return median;
  }
}
