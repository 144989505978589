import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getColor'
})
export class GetColorPipe implements PipeTransform {

  transform(i, temp): boolean {
    return i < temp;
  }

}
