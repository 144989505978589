import { Pipe, PipeTransform } from '@angular/core';
import { isPlainObject } from 'lodash';

@Pipe({
  name: 'includeParameter'
})
export class IncludeParameterPipe implements PipeTransform {

  transform(arr, item): boolean {
    return Object.keys(arr).includes(item);
  }

}
