import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-order-files',
  templateUrl: './order-files.component.html',
  styleUrls: ['./order-files.component.scss'],
})
export class OrderFilesComponent implements OnInit {
  @Input() order: any = {};

  constructor(public dataService: DataService) {
  }

  ngOnInit() {
    this.dataService.getOrderFiles(this.order.id);
  }

}
