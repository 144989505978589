import { Component, OnInit, EventEmitter, Output, Input, forwardRef } from '@angular/core';
import { DataService } from '../../../data.service';

import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { AuthService } from '../../../auth.service';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectClientComponent),
  multi: true
};

@Component({
  selector: 'select-client',
  templateUrl: './select-client.component.html',
  styleUrls: ['./select-client.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]

})
export class SelectClientComponent implements ControlValueAccessor {
  @Output() selectClient: EventEmitter<any[]> = new EventEmitter();
  public innerValue: any = { id: '', search: '' };
  public progress: Number = 0;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor(public dataService: DataService, public AuthService: AuthService) { }

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  selectClientEvent(item) {

    this.selectClient.emit(item);

    this.innerValue = item;

    this.onChangeCallback(this.innerValue);

  }


}
