import * as _ from "lodash";
import { toNumber } from "lodash";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { IMagicMoreInfo, IMagicsInput, IMagicsParams } from "../interfaces/magics";
import { IProductInput } from "../interfaces/products";
import { MagicsService } from "../services/magics.service";
import { SettingService } from "../services/setting.service";
import { MainMagics } from "./mainMagics";
import { TranslateProvider } from "../providers";
import { DataService } from "../data.service";

export class PrintPoints extends MainMagics {
    constructor(public magicsService: MagicsService, private settingService: SettingService, public translate: TranslateProvider, public dataService: DataService) {
        super(magicsService, dataService);
    }
    input: IMagicsInput[] = [
        {
            name: 'points',
            type: 'number',
            label: [{ text: 'נקודות' }],
            required: false,
        }
    ]


    async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput): Promise<IMagicMoreInfo> {
        let copies;
        const basic = [];
        let objectCopies = this.magicsService.getNumberOfPages(design.size.width, design.size.height, template.print.copies, template)
        copies = _.get(objectCopies, ['copies'], 0);


        let pointsNumber = 0;
        if (objectCopies && copies) {
            const machineID = this.magicsService.getMachineID(template, design);
            const colorfulParams = this.magicsService.getMachineColorful(template)
            const pointsAmount = _.get(this.settingService.machines, [machineID, 'parameters', colorfulParams, 'printPoints'], [])

            const pointsAmountSort = pointsAmount.sort(
                (a, b) => parseFloat(b.pagesAmount) - parseFloat(a.pagesAmount)
            )
            const twoSided = this.magicsService.getTwoSided(template)

            if (copies && twoSided) {
                const extensionPages = this.getExtensionByName(template, "pages");
                const pages = _.get(extensionPages, ["input", "values", "pages"], 1);
                if (pages == 1) {
                    copies = copies * 2
                }
            }

            const amountIndex = pointsAmountSort.findIndex(amountLine => copies >= parseFloat(amountLine.pagesAmount));
            let pointAmount = 0
            if (pointsAmountSort[amountIndex] && pointsAmountSort[amountIndex - 1]) {
                const current = pointsAmountSort[amountIndex].pointsAmount * copies;
                const next = pointsAmountSort[amountIndex - 1].pointsAmount * pointsAmountSort[amountIndex - 1].pagesAmount;
                if (current > next) {
                    pointsNumber = next
                    pointAmount = pointsAmountSort[amountIndex].pointsAmount;
                } else {
                    pointsNumber = current
                    pointAmount = pointsAmountSort[amountIndex - 1].pointsAmount;
                }
            } else if (pointsAmountSort[amountIndex]) {
                pointsNumber = pointsAmountSort[amountIndex].pointsAmount * copies;
                pointAmount = pointsAmountSort[amountIndex].pointsAmount
            }

            const minimumPoints = _.get(this.settingService.machines, [machineID, 'parameters', colorfulParams, 'minimumPoints'], 0)

            if (minimumPoints > pointsNumber) {
                pointsNumber = minimumPoints
                basic.push({
                    label: this.translate.get('641_MINIMUM_POINTS'),
                    description: minimumPoints,
                })

            } else {
                pointsNumber = pointsNumber
            }
            basic.push({
                label: twoSided ? this.translate.get('566_PRINTS_INCLUDING_BILATERAL') : this.translate.get('568_PRINTS'),
                description: copies,
            })

            basic.push({
                label: this.translate.get('567_PONGS_FOR_ANY_PRINTING'),
                description: pointAmount,
            })

            const typePaperID = this.magicsService.getPaperType(template, design)
            const typePaperData = this.settingService.getPaperPrice(typePaperID);

            if (typePaperData.addPercentagePoints) {
                const extraForPaperType = Math.round((pointsNumber * (typePaperData.addPercentagePoints / 100)) * 100) / 100
                pointsNumber = pointsNumber + extraForPaperType
                basic.push({
                    label: this.translate.get("639_EXTENSION") + ' ' + extraForPaperType + ' ' + this.translate.get("640_POINTS_FOR_PAPER") + ' ' + typePaperData.name,
                    description: `${typePaperData.addPercentagePoints}%`
                })
            }
            return {
                basic,
                pointsNumber,
                data: {},
            };
        }
    }

}