import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormLineComponent } from './form-line.component';
import { ButtonsModule } from '../buttons.module';
import { InputModule } from '../input/input.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [FormLineComponent],
  imports: [
    CommonModule,
    InputModule,
    IonicModule,
    TranslateModule
  ],
  exports: [FormLineComponent]
})
export class FormLineModule { }
