import { Pipe, PipeTransform } from '@angular/core';
import { StepsService } from '../services/steps.service';

@Pipe({  name: 'equalStep'})
export class EqualStepPipe implements PipeTransform {

  transform(steps: StepsService, index: number, value: string): boolean {
    return steps.getStep(index) === value;
  }

}
