import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ToastController, LoadingController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class ApiHttpService {

  constructor(private http: HttpClient,
    public toastCtrl: ToastController,
    public loadingCtrl: LoadingController
) {}

  loader: any;

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  async req(uri, data = {}, type = 'get') {
    return new Promise((resolve, reject) => {
      this.showLoading();
      data['version'] = environment.version;
      const domainUrl = location.port === '8100' ? environment.firebase_url : environment.firebase_url || 'https://us-central1-generator-541da.cloudfunctions.net/api';
      this.http[type](domainUrl + '/v1/' + uri, { params: data, observe: 'response' })
        // .pipe(
        //   catchError(this.handleError)
        // )
        .subscribe(
          (data_server: any) => {
           if (this.loader) {
               this.loader.dismiss();
           }

            // if exists message
            if (data_server['message']) {
              this.showToast(data_server['message']);
            }
            resolve(data_server);
          },
          this.handleError
        );
    });
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    console.log('error',error);
    
    this.loader.dismiss();

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
  async showLoading() {
    this.loader = await this.loadingCtrl.create();

    this.loader.present();

  }

  // if exists message
  async showToast(message) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 3000,
      position: 'bottom'
    });

    toast.present();

  }

}
