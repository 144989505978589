import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { AuthService } from './auth.service';
import {
    ModalController, AlertController, NavController, LoadingController
} from '@ionic/angular';
import { LoginPage } from './pages/login/login.page';
import { environment } from '../environments/environment';
import { ApiHttpService } from './api-http.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Title, DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { DeleteComponent } from './pages/delete/delete.component';
import { WarningComponent } from './pages/warning/warning.component';
import { ISize, ISizes } from './interfaces/sizes';
import { ICart, ICustomer, IShipping } from './interfaces/cart';
import { IPersonalSizeProducts } from './interfaces/products';
import { TranslateService } from '@ngx-translate/core';
import { VatService } from './services/vat.service';
import { SettingService } from './services/setting.service';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    allFiles: any = {};
    files: any = {};
    system: any = environment.system;
    moment: any = moment;
    messages: Array<string> = [];
    categoryFilter$: BehaviorSubject<string | null>;
    categories: any = [];
    categoriesObject: any = {};
    designs: any = [];
    sizes: ISizes = {};
    texts: any = {};
    prices: any = {};
    allOrder: any = {};
    printingsHouses: any = '';
    orders: any = {};
    allOrders: any;
    ordersCostumer: any = [];
    designsLoad$: any = new Subject();
    sizesLoad$: any = new Subject();
    clientsLoaded$: any = new Subject();
    designsObject: any = {};
    designsContents: any = {};
    mainCategories: any = [];
    categoriesBuilding: any = {};
    authors: any = {};
    filesList: any = [];
    data: any = {
        isBuildCategories: false,
    };
    products: any = {
    };
    productsTypes: any = {
        printing_products: {
            name: this.translate.get('580_PRINTING_PRODUCTS'),
            products: []
        },
        // documents: {
        //     name: 'מסמכים',
        //     products: []
        // },
        // WideFormatPrinting: {
        //     name: 'פורמט רחב',
        //     products: []
        // }
    };
    contentsTypes: any = [
        'Title',
        'Sub title',
        'Preliminary',
        'sub preliminary',
        'name',
        'Sub name',
        'Main Text',
        'Sub main Text',
        'End',
        'Sub end',
        'custom 1',
        'custom 2',
        'custom 3',
    ];

    cart: ICart;
    groups: any = {};
    cartImages: any = {};
    currency = 'ILS';
    stepsPrint: any = [];
    personalSizeProducts: IPersonalSizeProducts[] = [];
    priceLists: any = {};
    star: string = environment.star
    printingsHousesKey = 'gck';
    printingsHousesData: any = {};
    screenWidth = 1200;
    clients: any = [];
    ordersFiles: object = {};
    constructor(
        private afs: AngularFirestore,
        public modalController: ModalController,
        private AuthService: AuthService,
        private router: Router,
        public navCtrl: NavController,
        private storage: Storage,
        public apiHttpService: ApiHttpService,
        public analytics: AngularFireAnalytics,
        private alertController: AlertController,
        public loadingController: LoadingController,
        public titleService: Title,
        public settingService: SettingService,
        private sanitizer: DomSanitizer,
        private translate: TranslateService,
        public alertCtrl: AlertController,
        public vatService: VatService
    ) {
        this.getScreenWidth();
        this.getCart();
        this.getPrintingsHousesValue();
        this.getMainCategories();
        // get all prices
        this.getPrices();

        this.settingService.settingLoad$.subscribe(() => {
            this.getSize()

            this.getProducts();
            this.getGroups();
            this.getDesigns();
            // load cart
            this.loadCartFromLocalStorage();
            this.AuthService.onLogin$.subscribe((user) => {

                // if Connected user
                if (user) {
                    // get User Messages
                    //   this.getUserMessages();
                    if (this.system !== 'print' && this.AuthService.userType == 2) {

                        // get printings houses
                        this.getPrintingsHouses();

                        // get clients
                        this.getClients();

                    }

                } else {
                }
            });

            // this.updateTexts();

            this.getAuthors();

            this.analyticsEvent('init', { date: new Date() });

            if (this.system === 'print') {
                // get steps print
                this.getStepsPrint();

                // get printings houses
                this.getPrintingsHouses();

            }


            if (this.system !== 'print' && this.AuthService.userType == 2) {
                // get printings houses
                this.getPrintingsHouses();

                // get clients
                this.getClients();
            }
        });
    }
    // modal login
    async modalLogin() {
        const modal = await this.modalController.create({
            component: LoginPage,
            cssClass: 'login-modal',
        });
        await modal.present();
    }
    getPricing(sizeID) {
        if (this.priceLists[sizeID]) {
            return this.priceLists[sizeID]
        }
        //TODO
        // this.priceLists[sizeID] = {};
        return new Promise((resolve, reject) => {

            this.afs
                .doc(`sizes/${sizeID}/pricing/main`)
                .valueChanges()
                .subscribe((pricing) => {
                    if (!pricing) {
                        pricing = { main: {} }
                    }
                    this.priceLists[sizeID] = pricing;
                    resolve(pricing);
                })
        })

    }
    updateFilterCategory() {
        this.categoryFilter$.next('true');
    }

    // get user messages
    getUserMessages() {
        if (!this.AuthService.user) {
            return false;
        }

        this.afs
            .collection('users/' + this.AuthService.user['uid'] + '/messages')
            .snapshotChanges()
            .pipe(
                map((actions: any) => {
                    return actions.map((a) => {
                        const data: Object = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    });
                })
            )
            .subscribe((messages) => {
                this.messages = messages;
            });
    }

    // get Main Categories
    async getMainCategories() {
        const loading = await this.showLoading();

        this.afs
            .collection('mainCategories')
            .snapshotChanges()
            .pipe(
                map((actions: any) => {
                    return actions.map((a) => {
                        const data: Object = a.payload.doc.data();
                        const id = a.payload.doc.id;

                        return { slideActive: 0, id, ...data };
                    });
                })
            )
            .subscribe((mainCategories) => {
                this.mainCategories = mainCategories;
                this.getCategories(loading);
            });
    }

    // get Main Categories
    getCategories(loading = null) {
        this.afs.collection('categories', ref => ref.orderBy('ordering'))
            .snapshotChanges()
            .pipe(
                map((actions: any) => {
                    return actions.map((a) => {
                        const data: Object = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    });
                })
            )
            .subscribe((categories) => {
                this.categories = categories;

                if (loading) {
                    // loading dismiss
                    loading.dismiss();

                }

                // build data categories
                this.buildCategories();
            });
    }
    getGroups() {
        this.afs.collection('groups')
            .snapshotChanges().pipe(
                map((actions: any) =>
                    actions.map((action) => ({
                        id: action.payload.doc.id,
                        ...action.payload.doc.data()
                    })))
            )
            .subscribe(data => {
                if (data) {
                    for (const group of data) {
                        this.groups[group.id] = group;
                        this.groups[group.id].products = [];
                    }
                    for (const product of _.values(this.products)) {
                        if (this.groups[product.groupID]) {
                            this.groups[product.groupID].products.push(product);
                        }
                    }
                }
            });
    }
    getPrintingsHousesValue() {
        this.afs.collection('printingsHousesP')
            .snapshotChanges().pipe(
                map((actions: any) => {
                    return actions.map((a) => {
                        const data: Object = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    });
                })
            )
            .subscribe(data => {
                if (data) {
                    this.printingsHouses = data
                }
            }
            );
    }
    // get files
    getOrderFiles(orderId) {
        if (!orderId || this.ordersFiles[orderId]) {
            return;
        }

        this.afs.collection('files', ref => ref.where('orderID', '==', orderId))
            .snapshotChanges().pipe(
                map((actions: any) => {
                    return actions.map((a) => {
                        const data: Object = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    });
                })
            )
            .subscribe(data => {
                if (data) {
                    this.ordersFiles[orderId] = data;
                }
            }
            );
    }



    // get Main Categories
    getDesigns() {
        this.afs
            .collection('designs')
            .snapshotChanges()
            .pipe(
                map((actions: any) => {
                    return actions.map((a) => {
                        const data: Object = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        const preview = `${environment.firebaseStorageUrl}/previews%2F200x200%2F${data['backgroundUrl']}?alt=media`;
                        const previewM = `${environment.firebaseStorageUrl}/previews%2F600x600%2F${data['backgroundUrl']}?alt=media`;
                        const wantPrint: Boolean = false;
                        const pricePackage = (data['pricePackage'] || data['pricePackage'] === 0) ? parseFloat(data['pricePackage']) : 120;
                        const allProducts = 'true';
                        return { id, preview, previewM, wantPrint, allProducts, ...data, pricePackage };
                    });
                })
            )
            .subscribe((designs) => {
                this.designs = designs;
                this.designs.sort((a, b) => a.serial - b.serial)

                // build data categories
                this.buildCategories();

                const designsObject = {};
                const designsContents = {};

                this.designs.map((design) => {
                    design.defaultPrice = this.getDefaultPrice(design);
                    designsObject[design.id] = design;

                    // if (design.texts) {
                    //     design.texts.map((content) => {
                    //         if (!designsContents[content]) {
                    //             designsContents[content] = [];
                    //         }
                    //         designsContents[content].push(design);
                    //     });
                    // }
                });

                this.designsObject = designsObject;
                this.designsContents = designsContents;
            });
    }
    // build data categories
    buildCategories() {
        if (this.data.isBuildCategories === true) {
            return;
        }

        if (
            !this.mainCategories.length ||
            !this.designs.length ||
            !this.categories.length
        ) {
            return;
        }

        this.data.isBuildCategories = true;

        const categoriesObject = {};

        const categoriesBuilding = {};

        this.mainCategories.map((mainCategory) => {
            // set to main categories Building
            categoriesBuilding[mainCategory.id] = {
                ...mainCategory,
                children: [],
                designs: [],
            };
        });

        this.categories.map((category, i) => {
            if (
                category.mainCategory &&
                categoriesBuilding[category.mainCategory]
            ) {

                category.mainCategoryName = categoriesBuilding[category.mainCategory].name;
                categoriesBuilding[category.mainCategory].children.push(
                    category
                );
            }

            // set to categories Object
            categoriesObject[category.id] = { ...category, designs: [] };
        });

        this.designs.map((design) => {
            if (
                design.mainCategory &&
                categoriesBuilding[design.mainCategory]
            ) {
                categoriesBuilding[design.mainCategory].designs.push(design);
            }

            // add the design to categories
            // if exists categories
            if (design.categories && typeof design.categories === 'object') {
                design.categories.map((category) => {
                    if (
                        categoriesObject[category] &&
                        categoriesObject[category].designs
                    ) {
                        categoriesObject[category].designs.push(design);
                    }
                });
            }
        });

        this.categoriesBuilding = categoriesBuilding;

        this.categoriesObject = categoriesObject;

        this.designsLoad$.next();
    }

    editprofile() {
        this.navCtrl.navigateForward('edit-profile');
    }

    pageMessages() {
        this.navCtrl.navigateForward('messages');
    }

    // loadPrice
    async getPrices() {
        this.afs
            .collection('prices')
            .snapshotChanges()
            .pipe(
                map((actions: any) => {
                    return actions.map((a) => {
                        const data: Object = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    });
                })
            )
            .subscribe((prices) => {
                prices.map((price) => {
                    this.prices[price.id] = price;
                });
            });
    }

    // get Size
    async getSize(sizeID = null) {
        if (sizeID) {
            // if exists price
            if (this.sizes[sizeID]) {
                return this.sizes[sizeID];
            }

            return {};
        } else {

            if (!_.isEmpty(this.sizes)) {
                return;
            }

            let measure = this.settingService.general.generalSettings.measure

            this.afs
                .collection('sizes')
                .snapshotChanges()
                .pipe(
                    map((actions: any) => {
                        return actions.map((a) => {
                            const data: Object = a.payload.doc.data();
                            const id = a.payload.doc.id;
                            const width = !data['surfing'] ? parseFloat(data['width']) : parseFloat(data['width']) - 1;
                            const height = !data['surfing'] ? parseFloat(data['height']) : parseFloat(data['height']) - 1;
                            const sizeDisplay = `${data['name']} | ${width}${measure} X ${height}${measure}`;
                            const sizeDisplayOnly = `${width}${measure} X ${height}${measure}`;
                            return { id, sizeDisplay, sizeDisplayOnly, ...data };
                        });
                    })
                )
                .subscribe((sizes: ISize[]) => {
                    sizes.map((size) => {
                        this.sizes[(size.id as any)] = size;
                    });

                    this.getPersonalSizeProducts();
                    this.sizesLoad$.next();
                });
        }
    }

    getPersonalSizeProducts() {
        if (_.isEmpty(this.sizes) || _.isEmpty(this.products) || this.personalSizeProducts.length) {
            return;
        }

        const personalSizeProducts = [];

        for (const sizeID in this.sizes) {
            const sizeData = this.sizes[sizeID];
            sizeData['product'] = { ...this.products[sizeData['productID']] };
            if (this.sizes[sizeID].pricingBy === 'customSize') {
                personalSizeProducts.push(this.sizes[sizeID]);
            }
        }
        this.personalSizeProducts = personalSizeProducts
    }

    saveCartToLocalStorage() {
        // update total amount
        this.updateTotalCart();
        this.setStorage('cart', this.cart);
    }

    async loadCartFromLocalStorage() {
        const cart = await this.getStorage('cart');

        if (cart && !cart.shipping) {
            cart.shipping = this.getShippingDefault();
        }


        // if cart exists
        if (cart) {
            // updating cart
            this.cart = cart || {};
        }
    }
    getCustomerDefault(): ICustomer {
        return {
            name: '',
            company: '',
            address: '',
            city: '',
            postalCode: ''
        };
    }

    getShippingDefault(): IShipping {
        return {
            address: {
                fullName: '',
                street: '',
                city: '',
                cityName: '',
                postal: '',
                phone: '',
                postalCode: '',
                note: '',
            },
            use: 'false',
        };
    }

    saveImage2() {

        const emitter = new EventEmitter();

        // setInterval(function(){
        //   emitter.emit('data');
        // }, 500)
        return emitter;


    }


    // save image to firestorage
    saveImage(file: any, folder, metadata = null) {
        const emitter = new EventEmitter();
        const extension = this.getNameExtensionFile(file);
        const fileName = 'A' + new Date().getTime() + '.' + extension;
        const path = `${folder}/${fileName}`;
        const storageRef = firebase.storage().ref();
        const mountainsRef = storageRef.child(path);
        const progress = mountainsRef.put(file);
        progress.then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
                emitter.emit({
                    type: 'done', data: {
                        url,
                        fileName,
                    }
                });

                try {
                    if (metadata) {
                        const myMetadata: any = this.getMetadata(metadata);
                        mountainsRef.updateMetadata({ customMetadata: myMetadata });
                    }
                } catch (error) {
                    console.error('error', error);
                }
            });
        })
            .catch((error) => {
                console.error('error!', error);
                emitter.emit({ type: 'error', data: { error } });
            });
        progress.on(firebase.storage.TaskEvent.STATE_CHANGED, (next) => {
            const progress = (next.bytesTransferred / next.totalBytes) * 100;
            emitter.emit({
                type: 'progress', data: {
                    progress
                }
            });
        });
        return emitter;
    }

    // update all texts
    // updateTexts() {
    //     //if not exists texts
    //     if (Object.entries(this.texts).length === 0) {
    //         //load the texts
    //         this.getTexts();
    //     }
    // }

    // get the texts
    // getTexts() {
    //     this.afs
    //         .collection("texts")
    //         .snapshotChanges()
    //         .pipe(
    //             map((actions: any) => {
    //                 return actions.map((a) => {
    //                     const data: Object = a.payload.doc.data();
    //                     const id = a.payload.doc.id;
    //                     return { id, ...data };
    //                 });
    //             })
    //         )
    //         .subscribe((texts) => {
    //             let textsObject = {};

    //             //run on all texts
    //             texts.map((text) => {
    //                 //if not exists categories
    //                 if (!text.categories) {
    //                     return false;
    //                 }

    //                 text.categories.map((categoryID) => {
    //                     if (!textsObject[categoryID]) {
    //                         textsObject[categoryID] = [];
    //                     }
    //                     textsObject[categoryID].push(text);
    //                 });
    //             });

    //             this.texts = textsObject;
    //         });
    // }

    async getStorage(key) {
        const value = await this.storage.get(key);

        try {
            if (_.isString(value)) {
                return JSON.parse(value);
            } else {
                return value;
            }
            return JSON.parse(value);
        } catch (error) {
            return value;
        }
    }

    setStorage(key, value) {
        // set a key/value
        return this.storage.set(key, value);
    }

    // remove Design From Cart
    async removeDesignFromCart(design) {
        const modal = await this.modalController.create({
            component: DeleteComponent,
            componentProps: { product: this.translate.instant("83_THE_PRODUCT") },
            cssClass: 'delete-component'
        });
        await modal.present();
        modal.onDidDismiss().then((value) => {
            if (value.data.delete) {
                delete this.cart.designs[design];
                this.saveCartToLocalStorage();
            }
        })
    }

    updateTotalCart() {
        let total = 0;
        let totalPrint = 0;
        let totalGraphics = 0;
        let totalPrintAfterCoupon = 0;
        let totalGraphicsAfterCoupon = 0;


        if (this.cart.coupon) {
            this.updateTotalCartWithCoupon();
        }


        for (const item in this.cart.designs) {

            total += parseFloat((this.cart.designs[item].totalAmount as any)) - parseFloat(this.cart.designs[item].amountDiscount as any);
            if (!!+this.cart.designs[item].totalAmountGraphics) {
                totalGraphics += parseFloat(this.cart.designs[item].totalAmountGraphics as any);
                totalGraphicsAfterCoupon = totalGraphics / 100 * (100 - this.cart.coupon.percent);
            }
            if (!!+this.cart.designs[item].totalAmountPrint) {

                totalPrint += parseFloat(this.cart.designs[item].totalAmountPrint as any);
                totalPrintAfterCoupon = totalPrint / 100 * (100 - this.cart.coupon.percent);
            }

        }

        try {
            const shippingPrice = this.getShippingPrice();
            total += shippingPrice;
        } catch (error) {
            console.log('error getShippingPrice', error);

        }


        // update cart
        this.cart.total = parseFloat(total.toFixed(2));
        this.cart.totalPrint = parseFloat(totalPrint.toFixed(2));
        this.cart.totalGraphics = parseFloat(totalGraphics.toFixed(2));
        this.cart.totalPrintAfterCoupon = parseFloat(totalPrintAfterCoupon.toFixed(2));
        this.cart.totalGraphicsAfterCoupon = parseFloat(totalGraphicsAfterCoupon.toFixed(2));
        this.cart.totalWithVAT = this.cart.total + (this.vatService.getVat(this.cart.total))
    }

    getShippingPrice() {
        return this.cart.shipping.use === 'true' ? 45 : 0;
    }

    // make Order
    async makeOrder() {
        if (!Object.keys(this.cart.designs).length || !this.AuthService.user) {
            return false;
        }

        const designsTemplates = await this.getDesignsTemplates();

        // order data
        const orderData = {
            cart: this.cart,
            designsTemplates: designsTemplates,
            userCreated: this.AuthService.user['uid'],
            printingsHousesKey: this.printingsHousesKey,
            currentSystem: environment.system,
            process: 'bid'

        };

        console.log('orderData', orderData);


        const makeOrder: any = await this.apiHttpService.req(
            'orders/orderMake',
            orderData,
            'post'
        );


        if (makeOrder.status && makeOrder.data.nextPage) {
            this.cart.order = {
                url: makeOrder.data.paymentUrl,
                amount: this.cart.total,
            };

            // reset Cart
            if (makeOrder.data.resetCart) {
                this.getCart();
                this.saveCartToLocalStorage();
            }
            if (environment.system !== 'print') {
                this.router.navigateByUrl('/' + makeOrder.data.nextPage);
            } else {
                return makeOrder.data.paymentUrl
            }
        }

        this.analyticsEvent('makeOrder', { userID: this.AuthService.user['uid'] });

    }
    random() {
        return Math.floor(Math.random() * 1001).toString()
    }
    // get Designs Templates
    async getDesignsTemplates() {
        return new Promise(async (res) => {
            const dataObjects = {};


            for (const designID in this.cart.designs) {

                dataObjects[designID] = {};

                // get templates design
                // dataObjects[designID] = {
                //     "0": await this.getStorage(
                //         `design_${designID}_0${this.cart.designs[designID].catID ? "_" + this.cart.designs[designID].catID : ""}`
                //     ),
                // };


                // if elements exists
                if (this.cart.designs[designID].templates) {
                    const hhhh = await Promise.all(
                        this.cart.designs[designID].templates.map(
                            async (template) => {
                                if (template.selected) {

                                    const designTemplate = await this.getStorage(
                                        `design_${designID}_${template.id}${this.cart.designs[designID].catID ? '_' + this.cart.designs[designID].catID : ''}`
                                    );

                                    dataObjects[designID][template.id] = designTemplate;

                                    if (template.layouts) {
                                        for (let i = 0; i < template.layouts.length; i++) {

                                            const designTemplate = await this.getStorage(
                                                `design_${designID}_${template.id}${this.cart.designs[designID].catID ? '_' + this.cart.designs[designID].catID : ''}_${i}`
                                            );

                                            dataObjects[designID][template.id + '_' + i] = designTemplate;
                                        }
                                    }

                                    return designTemplate;
                                }
                            }
                        ));
                }
            }

            res(dataObjects);
        });
    }

    changeSlideActive(indexMainCategory, indexCategory) {

        this.mainCategories[indexMainCategory].slideActive = indexCategory;
    }

    getMainCatIndex(mainCatID) {
        const index = this.mainCategories.findIndex((mainCat) => {
            return mainCat.id == mainCatID;
        });

        return index;
    }

    getCatIndex(mainCatID, catID) {

        if (!this.categoriesBuilding[mainCatID]) {
            return 0;
        }

        const index = this.categoriesBuilding[mainCatID].children.findIndex(
            (cat) => {
                return cat.id === catID;
            }
        );

        return index;
    }

    analyticsEvent(key, value) {
        this.analytics.logEvent(key, value);
    }

    getCartImage(item, template) {
        const key = this.getKeyCartItemImage(item, template);

        if (this.cartImages[key]) {
            return this.cartImages[key];
        } else {

            this.cartImages[key] = '/assets/icons/dots-05.svg';

            // get from local storage
            // this.getStorage(key).then((data) => {
            this.getStorage(key).then((data) => {

                if (data) {
                    fetch(data)
                        .then(res => res.blob())
                        .then((newDataBlob) => {
                            const blobUrl = window.URL.createObjectURL(newDataBlob);
                            this.cartImages[key] = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
                        });
                }
            });

            return '/assets/icons/dots-05.svg';

        }
    }

    getKeyCartItemImage(item, template) {
        //            //design_jgI9F9sSWxS0865zPsyj_0_SMKM0L8UujfsoY5BWiYF_image

        if (template) {
            return item.value.catID
                ? `design_${item.value.design.id}_${template.id}_${item.value.catID}_image`
                : `design_${item.value.design.id}_${template.id}_image`;

        } else {
            return item.value.catID
                ? `design_${item.value.design.id}_0_${item.value.catID}_image`
                : `design_${item.value.design.id}_0_image`;

        }
    }

    // reset Cart on finish
    getCart() {
        this.cart = {
            designs: {},
            totalPrint: 0,
            totalGraphics: 0,
            totalPrintAfterCoupon: 0,
            totalGraphicsAfterCoupon: 0,
            total: 0,
            order: {
                url: '',
                amount: 0,
            },
            coupon: {},
            customer: this.getCustomerDefault(),
            shipping: this.getShippingDefault(),
            pricePerPoint: ''
        };

    }

    getFileStorage(fileName, size = null) {
        const base = size ? `previews/${size}x${size}/` : '';

        const url = `https://firebasestorage.googleapis.com/v0/b/generator-541da.appspot.com/o/${encodeURIComponent(
            base + fileName
        )}?alt=media`;

        return url;
    }

    downloadFile(url, fileName) {
        let loading: any;

        fetch(url)
            .then(async (resp: any) => {
                if (resp.status === 200) {
                    loading = await this.showLoading();

                    return resp.blob();
                } else {
                    return this.showMessage(this.translate.get('581_PROCESS_THE_ORDER_PLEASE_WAIT_PLEASE_TRY_A_FEW_MORE_MINUTES_AGAIN'));
                }
            })
            .then((blob) => {

                if (blob) {

                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = fileName;
                    a.click();
                    // window.URL.revokeObjectURL(url);

                    // loading dismiss
                    loading.dismiss();

                }
            })
            .catch(() => {
                return this.showMessage(this.translate.get('331_ERROR'));
            });
    }


    // get Default Price to design
    getDefaultPrice(design) {
        if (
            this.designsObject[design.id] &&
            this.designsObject[design.id].defaultPrice
        ) {
            return this.designsObject[design.id].defaultPrice;
        }

        // get default size
        const priceID =
            design.sizes && design.sizes[design.sizeID]
                ? design.sizes[design.sizeID]
                : false;

        if (!priceID) {
            return {};
        }

        const priceData = this.prices[priceID];

        if (!priceData) {
            return {};
        }

        // get prices
        let priceAmount, originalPrice, priceOffAmount;

        // if exists price off
        if (design.percentOff) {
            priceOffAmount =
                Number(priceData.amount) * (Number(design.percentOff) / 100);
            priceAmount = Math.round(priceData.amount - priceOffAmount);
            originalPrice = priceData.amount;
        } else {
            priceAmount = Number(priceData.amount);
        }

        return { priceAmount, originalPrice };
    }

    displayDesignFromCart(item, templateIndex) {

        let link = item.value.catID ? `/designs/${item.key}/${item.value.catID}` : `/designs/${item.key}`;

        if (templateIndex) {
            link += `?templateActive=${templateIndex}`;
        }

        this.router.navigateByUrl(link);
    }

    getAuthors() {
        this.afs
            .collection('authors', (ref) => ref.where('status', '==', 1))
            .snapshotChanges()
            .pipe(
                map((actions: any) => {
                    return actions.map((a) => {
                        const data: Object = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    });
                })
            )
            .subscribe((authors) => {
                const authorsObject = {};

                // run on all texts
                authors.map((author) => {
                    authorsObject[author.id] = author;
                });

                this.authors = authorsObject;
            });
    }

    getProducts() {

        if (!_.isEmpty(this.products)) {
            return;
        }

        this.afs
            .collection('products', (ref) => ref.where('status', '==', 1).orderBy('order'))
            .snapshotChanges()
            .pipe(
                map((actions: any) => {
                    return actions.map((a) => {
                        const data: Object = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        const sizes: ISize[] = [];
                        const activeSize = '';
                        return { id, sizes, activeSize, ...data };
                    });
                })
            )
            .subscribe((products) => {

                const productsObject = {};

                // run on all texts
                products.map((product) => {
                    productsObject[product.id] = product;
                    if (this.productsTypes[product.type]) {
                        this.productsTypes[product.type].products.push(product);
                    }
                });
                this.products = productsObject;
                for (const sizeKey in this.sizes) {
                    const ind = this.sizes[sizeKey].productID
                    if (this.products[(ind as any)]) {
                        if (this.products[(ind as any)].activeSize === '') {
                            this.products[(ind as any)].activeSize = sizeKey;
                        }
                        this.products[(ind as any)].sizes.push({ ...this.sizes[sizeKey] });
                    }
                }

                this.getPersonalSizeProducts();
            });
    }

    async showMessage(message, time = 4000) {
        const alert = await this.alertController.create({
            message,
        });

        await alert.present();

        setTimeout(() => {
            alert.dismiss();
        }, time);
    }

    async showLoading() {
        const loading = await this.loadingController.create({
            spinner: null,
            message: `
              <div class="custom-spinner-container">
                <img src="assets/icons/dots-05.svg" />
              </div>`,
        });

        loading.present();
        return loading;
    }

    updateTotalCartWithCoupon() {

        if (!this.cart.coupon || !this.validCoupon()) {
            return false;
        }

        let sum = 0,
            designs: any = this.cart.coupon['designs']
                ? this.cart.coupon['designs']
                : '*',
            type = 'percent';

        switch (this.cart.coupon['type']) {
            case 'percentOff':

                sum = parseFloat(this.cart.coupon['percent'] as any);
                break;
            case 'amount':

                sum = parseFloat(this.cart.coupon['percent'] as any);
                type = 'amount';
                break;
            default:
                break;
        }


        let index = 0;
        for (const item in this.cart.designs) {
            index++;
            let amountDiscount = 0;

            if (
                (designs === '*' ||
                    (typeof designs === 'object' && designs.includes(item))) &&
                (!this.cart.coupon['count'] ||
                    index <= this.cart.coupon['count'])
            ) {

                const totalAmount = this.cart.coupon['includingPrinting'] ? this.cart.designs[item].totalAmount : (this.cart.designs[item].totalAmountGraphics || 0);

                switch (type) {
                    case 'percent':
                        amountDiscount = totalAmount * (sum / 100);
                        break;
                    case 'amount':
                        amountDiscount = totalAmount >= sum ? sum : totalAmount;
                        break;
                    default:
                        break;
                }
            }
            if (this.cart.designs && this.cart.designs[item]) {
                this.cart.designs[item].amountDiscount = amountDiscount;
            }

        }

        return;
    }

    validCoupon(couponData = null) {
        if (!couponData) {
            couponData = this.cart.coupon;
        }

        if (
            couponData.expired &&
            moment(couponData.expired.toDate()).isValid() &&
            moment(couponData.expired.toDate()).isBefore(moment(), 'day')
        ) {

            return false;
        }

        return true;
    }

    orderPrices(akv, bkv) {
        const a = akv.value.amount;
        const b = bkv.value.amount;

        return a > b ? 1 : b > a ? -1 : 0;
    }


    setTitle(title) {
        this.titleService.setTitle(title);
    }


    // get Steps Print
    async getStepsPrint() {
        const title =
            this.stepsPrint = [
                {
                    title: '114_PRODUCTS',
                    subTitle: '114_PRODUCTS',
                    data: {},
                    href: 'selected-product',
                    column: 'product',
                    open: false,
                    // icon: 'group15'
                },
                {
                    title: '539_DESIGN',
                    subTitle: '539_DESIGN',
                    data: {},
                    href: 'selected-product',
                    column: 'product',
                    open: false,
                    // icon: 'group53'
                },
                {
                    title: '540_DETAILS',
                    subTitle: '540_DETAILS',
                    href: 'design',
                    column: 'product',
                    open: false,

                },
                // {
                //     title: "עריכה",
                //     subTitle: "עריכה",
                //     data: {},
                //     href: "editing",
                //     column: "name",
                //     open: false,
                //     icon: 'group55'
                // },
                // {
                //     title: "מחירון",
                //     subTitle: "",
                //     data: {},
                //     href: "cart",
                //     column: "totalAmount",
                //     open: false,
                //     icon: 'group57'
                // },
                {
                    title: '465_SUMMARY',
                    subTitle: '465_SUMMARY',
                    data: {},
                    href: 'payment',
                    open: false,
                    // icon: 'group520'
                }
            ];

    }


    // addProductToCart(design,categoryID){

    // }


    getTemplateTotalPrice(template, design) {
        const printAmount = this.getPrintAmount(template, design);

        let designAmount = 0;

        if (design.allProducts != 'true') {
            designAmount = (template.selected && this.prices[template.priceID] ? this.prices[template.priceID].amount : 0);
        }
        return (designAmount + printAmount);
    }

    getPrintAmount(template, design) {
        return template && template.print && design.wantPrint == 'true' && template.print.total ? template.print.total : 0;
    }

    getTemplateGraphicsPrice(template, design) {
        let graphicsPrice = 0;

        if (design.allProducts != 'true') {
            graphicsPrice = (template.selected && this.prices[template.priceID] ? this.prices[template.priceID].amount : 0);
        }
        return graphicsPrice;
    }

    updateTemplateTotalAllDesigns() {
        for (const designID in this.cart.designs) {
            this.updateTemplateTotal(designID);
        }

    }

    updateTemplateTotal(designID) {

        if (!this.cart.designs[designID]) {
            return;
        }

        let elementsPrices = this.cart.designs[designID].allProducts == 'true' ? (this.cart.designs[designID].design as any).pricePackage || 0 : 0;
        let amountGraphics = elementsPrices;

        this.cart.designs[designID].templates.map((template) => {
            if (template && template.selected) {
                const elementsPrice = this.getTemplateTotalPrice(template, this.cart.designs[designID]);
                amountGraphics += this.getTemplateGraphicsPrice(template, this.cart.designs[designID]);
                elementsPrices += elementsPrice;
                template.templateTotalPrice = elementsPrice;
            }

        });

        this.cart.designs[designID].totalAmountGraphics = amountGraphics;
        this.cart.designs[designID].totalAmount = elementsPrices;

        this.saveCartToLocalStorage();

    }

    getPrintingsHouses() {
        this.afs.doc(`printingsHouses/${this.printingsHousesKey}`).valueChanges().subscribe((printingsHouses: any) => {
            this.printingsHousesData = printingsHouses;

        });
    }

    getScreenWidth() {

        this.screenWidth = _.get(window, ['screen', 'width'], 1200);
    }

    async getClients() {

        if (!_.isEmpty(this.clients)) {
            return;
        }
        await this.afs.doc(`clients/${this.printingsHousesKey}`).valueChanges().subscribe((clients: any) => {
            if (clients && clients.users) {
                const clientsSearch = [];
                for (const clientID in clients.users) {
                    const name = clients.users[clientID][0] || '';
                    const email = clients.users[clientID][1] || '';
                    const mobile = clients.users[clientID][2] || '';
                    const search = `${name} ${email} ${mobile}`;
                    const data = {
                        id: clientID, name, email, mobile, search
                    };
                    clientsSearch.push(data);
                }
                this.clients = clientsSearch;

                this.clientsLoaded$.next(true);
            }
        });
    }

    orderNumber(orderID) {
        return orderID ? orderID.substring(0, 5).toUpperCase() : '';
    }

    async getPDFfileData(file) {
        return new Promise((res, rej) => {
            if (window['pdfjs-dist/build/pdf']) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (readerEvent: any) => {
                    const loadingFile = (window['pdfjs-dist/build/pdf'] as any).getDocument(readerEvent.target.result);
                    loadingFile.promise.then((pdf) => {
                        const metadata = {};
                        const pages = [];
                        const numPages = _.get(pdf, ['_pdfInfo', 'numPages'], 0);

                        for (let indexPage = 1; indexPage < (numPages + 1); indexPage++) {
                            pdf.getPage(indexPage).then((page) => {
                                const viewport = page.getViewport({ scale: 1 });
                                const pageData = this.getPageData(viewport);
                                pages.push(pageData);
                            });
                        }

                        metadata['numPages'] = numPages;
                        metadata['pages'] = pages;
                        metadata['sizeDisplay'] = this.getFileSizeDisplay(file.size);

                        res(metadata);
                    });
                };
            } else {
                rej('not pdfjs-dist');
            }
        });
    }

    async getImageFileData(file) {
        return new Promise((res, rej) => {
            const viewport = new Image();
            viewport.src = URL.createObjectURL(file);
            viewport.onload = () => {

                const page = this.getPageData(viewport);
                const metadata = {};
                metadata['numPages'] = 1;
                metadata['pages'] = [page];
                metadata['sizeDisplay'] = this.getFileSizeDisplay(file.size);

                res(metadata);
            };
        });
    }

    getMetadata(metadata) {
        const metadataObject = _.mapValues(metadata, (value) => {
            if (_.isNumber(value) || _.isString(value)) {
                return value;
            } else if (_.isObject) {
                return JSON.stringify(value);
            }
        });
        return metadataObject;
    }

    getNameExtensionFile(file): string {
        const name = file.name;
        const lastDot = name.lastIndexOf('.');
        const extension = name.substring(lastDot + 1);
        return extension ? extension : 'jpg';
    }

    getRatio(width, height) {
        return (parseFloat(width) / parseFloat(height)).toFixed(1);
    }

    calculateCM(size: number, DPI: number = 300) {
        const inch = 2.54;
        const pxInCm = DPI / inch;
        return (size / pxInCm).toFixed(2);
    }

    getFileSizeDisplay(size) {
        return size < 1000000 ? Math.floor(size / 1000) + 'KB' : Math.floor(size / 1000000) + 'MB';
    }

    getPageData(viewport) {
        viewport['widthCM300'] = this.calculateCM(viewport.width, 300);
        viewport['heightCM300'] = this.calculateCM(viewport.height, 300);
        viewport['widthCM150'] = this.calculateCM(viewport.width, 150);
        viewport['heightCM150'] = this.calculateCM(viewport.height, 150);
        viewport['widthCM72'] = this.calculateCM(viewport.width, 72);
        viewport['heightCM72'] = this.calculateCM(viewport.height, 72);
        viewport['ratio'] = this.getRatio(viewport.width, viewport.height);
        return viewport;
    }

    async sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    getClientName(userID) {
        const clientData = _.find(this.clients, { id: userID });
        return clientData ? clientData.name : '----';
    }

    getClientData(userID) {
        const clientData = _.find(this.clients, { id: userID });
        return clientData ? clientData : {};
    }

    async deleteDesignFromCart(design) {

        const modal = await this.modalController.create({
            component: DeleteComponent,
            componentProps: { product: this.translate.instant("83_THE_PRODUCT") },
            cssClass: 'delete-component'
        });
        await modal.present();
        modal.onDidDismiss().then((value) => {
            if (value.data.delete) {
                if (design.value && design.value.parentID) {
                    const ind = this.cart.designs[design.value.parentID].innerSubProducts.indexOf(design.key);
                    if (ind > -1) {
                        this.cart.designs[design.value.parentID].innerSubProducts = this.cart.designs[design.value.parentID].innerSubProducts.filter(subProduct => subProduct != design.key)
                    }
                }
                if (this.cart.designs[design.key] && this.cart.designs[design.key].innerSubProducts) {
                    this.cart.designs[design.key].innerSubProducts.forEach(subProduct => {

                        if (this.cart.designs[(subProduct as any)] && this.cart.designs[(subProduct as any)].parentID === design.key) {
                            delete this.cart.designs[(subProduct as any)]
                        }
                    })
                }
                delete this.cart.designs[design.key];
                this.saveCartToLocalStorage();
            }
        })
    }

    getFiles$(orderId) {
        if (this.allFiles[orderId]) {
            return of(this.allFiles[orderId]);
        }
        return this.afs
            .collection('files', (ref) => ref.where('orderID', '==', orderId))

            .snapshotChanges()
            .pipe(
                map((actions: any) =>
                    actions.map((action) => ({
                        id: action.payload.doc.id,
                        ...action.payload.doc.data()
                    }))

                ),
                map(order => this.allFiles[orderId] = order)
            );

    }

    getOrder$(orderId) {
        if (this.allOrder[orderId]) {
            return of(this.allOrder[orderId]);
        }
        return this.afs
            .collection('orders', (ref) => ref.where('orderID', '==', orderId))
            .snapshotChanges()
            .pipe(
                map((actions: any) =>
                    actions.map((action) => ({
                        id: action.payload.doc.id,
                        ...action.payload.doc.data()
                    }))

                ),
                map(order => this.allOrder[orderId] = order)
            );

    }

    getOrders$(userCreated) {
        if (this.orders[userCreated]) {
            return of(this.orders[userCreated]);
        }
        return this.afs
            .collection('orders', (ref) => ref.where('userCreated', '==', userCreated))
            .snapshotChanges()
            .pipe(
                map((actions: any) =>
                    actions.map((action) => ({
                        id: action.payload.doc.id,
                        ...action.payload.doc.data()
                    }))

                ),
                map(order => this.orders[userCreated] = order)
            );
    }

    getAllOrders$() {
        if (this.allOrders) {
            return of(this.allOrders);
        }
        return this.afs
            .collection('orders')
            .snapshotChanges()
            .pipe(
                map((actions: any) =>
                    actions.map((action) => ({
                        id: action.payload.doc.id,
                        ...action.payload.doc.data()
                    }))
                ),
                tap(order => this.allOrders = order))
    }

    getOrdersByCostumer(costumerID) {

        if (this.ordersCostumer[costumerID]) {
            return this.ordersCostumer[costumerID];
        }
        return this.afs
            .collection('orders', (ref) => ref.where('costumerID', '==', costumerID))
            .snapshotChanges()
            .pipe(
                map((actions: any) =>
                    actions.map((action) => ({
                        id: action.payload.doc.id,
                        ...action.payload.doc.data()
                    }))
                )).subscribe((order) => {
                    this.ordersCostumer[costumerID] = order;
                    this.designsLoad$.next();
                })
    }

    async shoeWarning(warning: string, disabled) {
        const modal = await this.modalController.create({
            component: WarningComponent,
            componentProps: { warning, disabled },
            cssClass: 'warning'
        });
        await modal.present();
    }

    async addSizeStep1(productID) {
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('306_NEW_SIZE'),
            backdropDismiss: true,
            inputs: [
                {
                    name: 'name',
                    type: 'text',
                    placeholder: this.translate.instant('16_THERE')
                }
            ],
            buttons: [
                {
                    text: this.translate.instant('106_CANCELATION'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        console.log('Confirm Cancel');
                    }
                }, {
                    text: this.translate.instant('61_THE_NEXT'),
                    handler: textData => {
                        console.log("data", textData);
                        if (textData) {
                            this.addSizeStep2(productID, textData)
                        } else {
                            /*Do something with invalid data */
                        }
                    }
                },

            ]
        });
        await alert.present();
    }
    async addSizeStep2(productID, textData) {
        let customeSize = this.products[productID].sizes.find(item => item.pricingBy == "customSize")
        let inputs: any = [{
            name: "calculationMethod",
            type: "radio",
            label: this.translate.instant('91_SINGULARITY'),
            value: "units"
        },
        {
            name: "calculationMethod2",
            type: "radio",
            label: this.translate.instant('126_PACKAGES'),
            value: "packages"
        }]
        if (!customeSize) {
            inputs.push({
                name: "calculationMethod3",
                type: "radio",
                label: this.translate.instant('90_CUSTOM_FIT_BY_SIZE'),
                value: "customSize"
            })
        }

        this.alertCtrl.create({
            cssClass: "custom-alert",
            subHeader: this.translate.instant('89_CALCULATION_METHOD'),
            backdropDismiss: false,
            inputs: inputs,
            buttons: [
                {
                    text: this.translate.instant('106_CANCELATION'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        console.log('Confirm Cancel');
                    }
                },
                {
                    text: this.translate.instant('19_KEEPING'),
                    handler: async (pricingBy) => {
                        if (!textData.name || textData.name === '') {
                            this.showMessage(this.translate.instant('70_MANDATORY_NAME_FIELD'));
                            return;
                        }
                        if (textData.name.length >= 20) {
                            this.shoeWarning(this.translate.instant('107_MEASURE_NAME_IS_LIMITED_TO__CHARACTERS'), true)
                            return
                        }

                        if (pricingBy == "customSize") {
                            let sameName = this.products[productID].sizes.find(item => item.name == textData.name)
                            if (sameName) {
                                this.showMessage(this.translate.instant('642_THERE_IS_A_SIZE_IN_THIS_NAME'))
                                return
                            }

                            const newSize: any = {
                                name: textData.name,
                                height: 16,
                                width: 16,
                                productID: productID, surfing: false, subProducts: false,
                                pricingBy: pricingBy
                            };

                            const product = await this.afs.collection(`sizes`).add(newSize);
                            newSize.id = (product as any).f_.path.segments[1];
                            if (!this.products[productID].sizes || !this.products[productID].sizes.length) {
                                this.products[productID].sizes = [newSize];
                            } else {
                                this.products[productID].sizes.push(newSize);
                            }
                        }
                        else {
                            this.addSizeStep3(productID, textData, pricingBy)
                        }
                    }
                }
            ]
        }).then(alert => alert.present());
    }
    async addSizeStep3(productID, textData, pricingBy) {
        this.alertCtrl.create({
            cssClass: "custom-alert",
            subHeader: this.translate.instant('382_SIZE_DEFINITION'),
            backdropDismiss: false,
            inputs: [
                {
                    name: 'width',
                    type: 'number',
                    placeholder: this.translate.instant('28_WIDTH')
                },
                {
                    name: 'height',
                    type: 'number',
                    placeholder: this.translate.instant('29_HEIGHT')
                }
            ],
            buttons: [
                {
                    text: this.translate.instant('106_CANCELATION'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        console.log('Confirm Cancel');
                    }
                },
                {
                    text: this.translate.instant('19_KEEPING'),
                    handler: async (newData) => {
                        console.log("newData", newData);
                        const newSize: any = {
                            name: textData.name,
                            height: newData.height ? newData.height : 16,
                            width: newData.width ? newData.width : 16,
                            productID: productID, surfing: false, subProducts: false,
                            pricingBy: pricingBy
                        };
                        const product = await this.afs.collection(`sizes`).add(newSize);
                        newSize.id = (product as any).f_.path.segments[1];
                        if (!this.products[productID].sizes || !this.products[productID].sizes.length) {
                            this.products[productID].sizes = [newSize];
                        } else {
                            this.products[productID].sizes.push(newSize);
                        }
                    }
                }
            ]
        }).then(alert => alert.present());
    }

    getDefaultValue(item, size: ISize) {
        const defaultValue = _.get(size, ["product", "defaultValue", item])
        return defaultValue
    }
}

