import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sum'
})
export class SumPipe implements PipeTransform {

  transform(array, type): Number {
    if (!array || !type) {
      return 0;
    }
    let sum = 0;
    Object.values(array).map(item => item[type] ? sum += item[type] : true);
    return sum;
  }

}
