import { Component, Input, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { environment } from '../../../environments/environment';
import { DataService } from '../../data.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  constructor(private dataService: DataService) { }
  printingHouseName: string;
  userName: string;
  // language = environment.language;
  person: string = environment.redPerson;
  @Input() line = true;
  ngOnInit() {
    this.dataService.designsLoad$.subscribe(_ => {
      this.printingHouseName = this.dataService.printingsHouses.name;
      if (firebase.auth().currentUser && firebase.auth().currentUser.displayName) {
        this.userName = firebase.auth().currentUser.displayName;
      }
    });
    if (firebase.auth().currentUser && this.dataService.printingsHouses) {
      this.printingHouseName = this.dataService.printingsHouses.name;
      this.userName = firebase.auth().currentUser.displayName;
    }
  }
  // change(ev) {
  //   this.languageService.changeLanguage(this.languageService.language.find(l => l.value === ev.detail.value).value)
  // }
}
