import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth.service';
import { DataService } from '../../data.service';
import { IDesign } from '../../interfaces/design';
import { ISize } from '../../interfaces/sizes';
import { MagicsService } from '../../services/magics.service';
import { PaperService } from '../../services/paper.service';
@Component({
  selector: 'app-size-counts',
  templateUrl: './size-counts.component.html',
  styleUrls: ['./size-counts.component.scss'],
})
export class SizeCountsComponent implements OnInit {
  @Input() @Output() printPrice: any = {};
  @Input() size: ISize = {};
  @Input() calculator = true;
  @Input() parentID: string;
  parameter = false;
  @Input() showYesNo = true;
  exclamationMark = environment.exclamationMark;
  @Output() emitterEventUpdate: EventEmitter<any[]> = new EventEmitter();
  @Input() design: { key: string, value: IDesign };
  constructor(public dataService: DataService, private magicsService: MagicsService, public authService: AuthService, private paper: PaperService) { }

  ngOnInit() {
    
    if (!this.printPrice.copies && this.size['quantities']) {
      const copies = this.size.pricingBy === 'customSize' ? 100 : this.size['quantities'][0]
      this.printPrice.copies = copies;
    }
    if (!this.printPrice.copies) {
      this.printPrice.copies = 0;
    }
  }
  
  updateData() {
    this.emitterEventUpdate.emit(this.printPrice);
  }


}
