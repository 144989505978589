import { Component, OnInit } from '@angular/core';

import { NavController } from '@ionic/angular';


import { Pages } from './interfaces/pages';
import { AuthService } from './auth.service';
import { DataService } from './data.service';
import { LanguageService } from './services/language.service';
/**
 * Main Wrap App Component with starting methods
 *
 * @export
 * @class AppComponent
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  /**
   * Creates an Array instance with <Pages> interface that receives all menu list.
   *
   * @type {Array<Pages>}
   * @memberof AppComponent
   */
  public appPages: Array<Pages> = [];
  /**
  * Creates an instance of AppComponent.
  * @param {Platform} platform
  * @param {SplashScreen} splashScreen
  * @param {StatusBar} statusBar
  * @param {TranslateProvider} translate
  * @param {TranslateService} translateService
  * @param {NavController} navCtrl
  * @memberof AppComponent
  */
  constructor(

    public navCtrl: NavController,
    public AuthService: AuthService,
    public dataService: DataService,
    private languageService: LanguageService
  ) {
    // this.toggleZoomScreen()

    this.languageService.initializeApp();

  }

  ngOnInit(): void {
  }
  /**
   * Method that starts all Cordova and Factories
   *
   * @memberof AppComponent
   */





  /**
   * Navigate to Edit Profile Page
   *
   * @memberof AppComponent
   */
  goToEditProgile() {
    //  this.navCtrl.navigateForward('edit-profile');
  }
  /**
   * Logout Method
   *
   * @memberof AppComponent
   */
  logout() {
    this.navCtrl.navigateRoot('login');
  }



}
