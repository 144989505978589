import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-artime-menu',
  templateUrl: './artime-menu.component.html',
  styleUrls: ['./artime-menu.component.scss'],
})
export class ArtimeMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
