import { Pipe, PipeTransform } from '@angular/core';
import { log } from 'console';

@Pipe({
  name: 'extensions'
})
export class ExtensionsPipe implements PipeTransform {

  transform(value): any {
    let result = '';
    const { values, defaultValue, name } = value.input;
    if (values && defaultValue && name) {
      Object.keys(values).map((key) => {
        if (key == defaultValue) {
          result = ` : ${values[key]}`;
        }
      });
    }
    return result;
  }
}

