import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicTableComponent } from './dynamic-table.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../../pipes/pipes.module';
import { RoundButtonModule } from '../../buttons/round-button/round-button.module';
import { ButtonsModule } from '../../buttons/buttons.module';



@NgModule({
  declarations: [DynamicTableComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    RoundButtonModule,
  ],
  exports: [DynamicTableComponent]
})
export class DynamicTableModule { }
