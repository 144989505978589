import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'getDirection'
})
export class GetDirectionPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {

  }
  transform( ): string {
    return this.languageService.myLanguage === 'he' ? 'rtl' : 'ltr';
  }

}
