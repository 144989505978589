import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateProvider } from '../providers';
import { AuthService } from '../auth.service';
import { environment } from '../../environments/environment';
import { DataService } from '../data.service';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  language = [{
    name: 'עברית',
    value: 'he'
  },
  {
    name: 'english',
    value: 'en'
  }]
  myLanguage = environment.language;
  constructor(private platform: Platform,
    private statusBar: StatusBar,
    private translate: TranslateProvider,
    private dataService: DataService,
    private translateService: TranslateService,
    public authService: AuthService) {
    this.init()
  }
  init() {
    this.dataService.getStorage('language').then(lan => {
      if (!lan) {
        lan = environment.language
      }
      this.changeLanguage(lan)
    })
  }
  changeLanguage(lan) {
    this.myLanguage = lan;
    this.dataService.setStorage('language', lan)
    this.initializeApp();
  }
  initializeApp() {
    this.translateService.setDefaultLang(this.myLanguage);
    this.translateService.use(this.myLanguage);
    this.translateService.getTranslation(this.myLanguage).subscribe(translations => {
      this.translate.setTranslations(translations);
    });
    // if is manager
    if (this.authService.userType && window.location.pathname == '/' && environment.system !== 'print') {
      // this.router.navigateByUrl('walkthrough');
    }

    this.platform.ready().then(() => {

      this.translateService.setDefaultLang(this.myLanguage);
      this.translateService.use(this.myLanguage);
      this.translateService.getTranslation(this.myLanguage).subscribe(translations => {
        this.translate.setTranslations(translations);
      });

      this.statusBar.styleDefault();
    }).catch(() => {
    });
  }
}
