import { IMagicsInput, IMagicsParams } from '../interfaces/magics';
import { MainMagics } from './mainMagics';
import * as _ from 'lodash';
import { MagicsService } from '../services/magics.service';
import { ICartDesign, ICartTemplate } from '../interfaces/cart';
import { SettingService } from '../services/setting.service';
import { TranslateProvider } from '../providers';
import { DataService } from '../data.service';

export class WidePrintPaperWear extends MainMagics {
    data: any = {};
    inputs: IMagicsInput[] = [
        {
            name: 'paperRollsWidth',
            type: 'text',
            label: [
                {
                    "text": this.translate.get("62_WIDTH_OF_PAPER_ROLLS")
                }],
            required: true,
            defaultValue: 9
        },
    ];

    constructor(magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
        super(magicsService, dataService);
    }


    getPrice(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, machinesKey?: string): number {
        let paperRollsWidth = [1, 1.6, 2];
        if (typeof magicData.paperRollsWidth === 'string') {
            paperRollsWidth = magicData.paperRollsWidth.split(',').map((item) => {
                return parseFloat(item);
            });
        }
        const width: number = _.get(template, 'design.size.width', 0);
        const height: number = _.get(template, 'design.size.height', 0);
        if (!width || !height) {
            return 0;
        }
        const maxWidth = _.max([width, height]);
        const minHeight = _.min([width, height]);
        const myWear = [];
        const printSize = count;
        ['toWidth', 'toHeight'].map((side) => {
            paperRollsWidth.map((roll) => {
                const keyCalc = side === 'toWidth' ? minHeight : maxWidth;
                const keyCalcRes = side === 'toWidth' ? maxWidth : minHeight;
                if (roll >= keyCalcRes) {
                    const size = roll * keyCalc;
                    const wear = size - printSize;
                    if (wear >= 0) {
                        myWear.push(wear);
                    }
                }
            });
        });
        const min = _.min(myWear) * parameterPrice;
        return min;
    }


    isHiddenInput(): boolean {
        return false;
    }


}
