import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StepsService {
  steps: string[] = !!localStorage.getItem('status') ? JSON.parse(localStorage.getItem('status')) : ['false', 'false', 'false', 'false'];
  constructor() { }
  changeStep(i) {
      for (let ind = 0; ind < 4; ind++) {
      if (ind < i) {
        this.steps[ind] = 'pass';
      }
      if (ind > i) {
        this.steps[ind] = 'false';
      }
    }
    this.steps[i] = 'current';
    localStorage.setItem('status', JSON.stringify(this.steps));


  }
  getStep(i) {
    return this.steps[i];
  }
  getCurrentStep() {
    return this.steps.findIndex(b => b == 'current');
  }
}
