import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-round-button',
  templateUrl: './round-button.component.html',
  styleUrls: ['./round-button.component.scss'],
})
export class RoundButtonComponent implements OnInit, OnChanges {
  @Input() text: string;
  @Input() classes: string;
  constructor() { }

  ngOnInit() { }
  ngOnChanges() {
  }
}
