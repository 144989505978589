import { Pipe, PipeTransform } from '@angular/core';

@Pipe({  name: 'getValidValue'})
export class GetValidValuePipe implements PipeTransform {

  transform(printPrice: any, key: string): any {
     if (!printPrice)   { return null; }
     if (!printPrice.extensions)   { return null; }
     if (!printPrice.extensions[key])   { return null; }
     if (!printPrice.extensions[key].input)   { return null; }
     return printPrice.extensions[key].input.selected;
  }

}
