import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
})
export class WarningComponent implements OnInit {
  @Input() warning;
  @Input() disabled = false;
  warningImg: string = environment.warning;
  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }
  wanted() { this.modalController.dismiss({ continue: false }); }
  mistake() { this.modalController.dismiss({ continue: true }); }
}

