import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttpService } from './../api-http.service';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private apiHttpService: ApiHttpService, ) { }

  getUserById(userId: string) {
    return this.apiHttpService.req('v1/users/getUserById', { userId: '8yA8a8AptvacaKydzZ7iAHeLcR02' });
  }

  async addNewUser(data) {
    return await this.apiHttpService.req('v1/users/addUser', data);
  }
}
