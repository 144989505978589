import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { PopoverController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { TranslateProvider } from '../../providers';

@Component({
    selector: 'app-add-layout',
    templateUrl: './add-layout.component.html',
    styleUrls: ['./add-layout.component.scss'],
})
export class AddLayoutComponent implements OnInit {
    form: any = {
        name: null,
        icon: null,
        backgroundUrl: null,
        sizeID: null,
    };
    @Input() designID;
    @Input() template;
    @Input() action;
    @Input() layouts = [];
    @Input() colorSelected = null;
    @Input() layoutIndex = -1;
    title = '';
    environment: any = environment;

    constructor(
        public dataService: DataService,
        private afs: AngularFirestore,
        public popoverController: PopoverController,
        private translate: TranslateProvider,
    ) { }

    ngOnInit() {
        switch (this.action) {
            case 'new':
                this.title =  this.translate.get("20_ADDED_A_NEW_LAYOUT");
                break;
            case 'edit':
                this.title = this.translate.get("21_LAYOUT_EDITING");
                this.updateForm();
                break;
            case 'editColor':
                this.title = `${this.translate.get("22_BACKGROUND_EDITING_FOR_COLOR")} ${this.colorSelected.name}`;
                break;
        }

    }

    updateForm() {
        if (!this.layouts[this.layoutIndex]) {
            return;
        }


        ['name', 'icon', 'backgroundUrl', 'sizeID'].map((type) => {
            if (this.layouts[this.layoutIndex][type]) {
                this.form[type] = this.layouts[this.layoutIndex][type];
            }

        });
    }


    dismissPopover() {
        this.popoverController.dismiss().then(() => { });
    }

    save() {
        switch (this.action) {
            case 'new':
                this.createLayout();
                break;
            case 'edit':
                this.updateLayout();
                break;
            case 'editColor':
                this.updateLayoutColor();
                break;

            default:
                this.dismissPopover();
                break;
        }
    }

    async createLayout() {
        if (!this.form.name || !this.form.icon || !this.form.backgroundUrl || !this.form.sizeID) {            
            alert(this.translate.get("23_ALL_FIELDS_ARE_MANDATORY"));
            return;
        } else {
            if (!this.layouts) {
                this.layouts = [];
            }
            this.layouts.push(this.form);

            // if update for tempalte
            if (this.template) {
                const newDoc = await this.afs
                    .collection(`designs/${this.designID}/templates`).doc(this.template).update({ layouts: this.layouts })
                    .then((newLayout) => {
                        this.dismissPopover();
                    });

            } else {
                // create new design
                // let newDoc = await this.afs
                //     .collection(`designs`).doc(this.designID).update({layouts: this.layouts})
                //     .then((newLayout) => {
                //         this.dismissPopover();
                //     });

            }
        }
    }

    async updateLayout() {

        if (!this.form.name || !this.form.icon || !this.form.backgroundUrl || !this.form.sizeID) {
            alert(this.translate.get("23_ALL_FIELDS_ARE_MANDATORY"));
            return;
        } else if (this.layoutIndex < 0) {
            alert(this.translate.get("24_NO_LAYOUT_SELECTED"));
            return;

        } else {
            this.layouts[this.layoutIndex] = this.form;

            // if update for tempalte
            if (this.template) {
                const newDoc = await this.afs
                    .collection(`designs/${this.designID}/templates`).doc(this.template).update({ layouts: this.layouts })
                    .then((newLayout) => {
                        this.dismissPopover();
                    });

            } else {
                // let newDoc = await this.afs
                //     .collection(`designs`).doc(this.designID).update({layouts: this.layouts})
                //     .then((newLayout) => {
                //         this.dismissPopover();
                //     });

            }
        }
    }

    async updateLayoutColor() {

        if (!this.form.backgroundUrl) {
            alert(this.translate.get("23_ALL_FIELDS_ARE_MANDATORY"));
            return;
        } else if (this.layoutIndex < 0 || !this.colorSelected.id) {
            alert(this.translate.get("24_NO_LAYOUT_SELECTED"));
            return;

        } else {
            this.layouts[this.layoutIndex][this.colorSelected.id] = this.form.backgroundUrl;

            // if update for tempalte
            if (this.template) {
                const newDoc = await this.afs
                    .collection(`designs/${this.designID}/templates`).doc(this.template).update({ layouts: this.layouts })
                    .then((newLayout) => {
                        this.dismissPopover();
                    });

            } else {
                // let newDoc = await this.afs
                //     .collection(`designs`).doc(this.designID).update({layouts: this.layouts})
                //     .then((newLayout) => {
                //         this.dismissPopover();
                //     });

            }
        }
    }
}
