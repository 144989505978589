import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getLength'
})
export class GetLengthPipe implements PipeTransform {

  transform(sizes): Number {
    return sizes.length ? sizes.length : Object.keys(sizes).length;
  }

}
