import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../data.service';

@Pipe({
  name: 'getName'
})
export class GetNamePipe implements PipeTransform {
constructor(private dataService: DataService) {}
  transform(orderID): any {
   return this.dataService.getFiles$(orderID);

  }

}
