import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes'
})
export class IncludesPipe implements PipeTransform {

  transform(arr: String[], item: String): any {

    return arr && arr.length && item && arr.includes(item) ? item : 'lllll';

  }

}
