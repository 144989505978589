import { Component, Input, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-file-info',
  templateUrl: './file-info.component.html',
  styleUrls: ['./file-info.component.scss'],
})
export class FileInfoComponent implements OnInit {

  public DPI = '300';
  public ruler = 'cm';
  @Input() fileData: any;
  constructor(
    public dataService: DataService,


  ) { }

  ngOnInit() {

  }

}
