import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DeleteComponent implements OnInit {
  @Input() product;
  trash: string = environment.bigTrash;
  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }
  wanted() { this.modalController.dismiss({ delete: true }); }
  mistake() { this.modalController.dismiss({ delete: false }); }
}
