import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteComponent } from './delete.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [DeleteComponent],
  imports: [
    FormsModule,
    CommonModule,
    IonicModule,
    TranslateModule
  ], exports: [DeleteComponent]
})
export class DeleteModule { }
