import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'include'
})
export class IncludePipe implements PipeTransform {

  transform(arr: String[], temp: string): Boolean {
    if (!arr || !arr.length) {
      return false;
    }
    return arr.join().includes(temp);
  }

}
