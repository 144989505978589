import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonInput } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FooterButtonsComponent } from './footer-buttons/footer-buttons.component';
import { TranslateModule } from '@ngx-translate/core';
import { TemplateMessagesComponent } from './template-messages/template-messages.component';
import { RoundButtonModule } from '../components/buttons/round-button/round-button.module';
import { DynamicFormModule } from '../components/buttons/dynamic-form/dynamic-form.module';
import { InputModule } from '../components/buttons/input/input.module';
import { PriceListAlertComponent } from './price-list-alert/price-list-alert.component';



@NgModule({
  declarations: [FooterComponent, FooterButtonsComponent, TemplateMessagesComponent, PriceListAlertComponent],
  imports: [
    CommonModule,
    FormsModule,
    DynamicFormModule,
    IonicModule,
    RouterModule,
    InputModule,
    TranslateModule,
    RoundButtonModule,
  ],
  exports: [FooterComponent, FooterButtonsComponent, TemplateMessagesComponent, PriceListAlertComponent],

})
export class ComponentsPrintModule { }
