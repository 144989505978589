import { Component, Input, OnInit } from '@angular/core';
import { IMagicMoreInfoAlert } from '../../interfaces/magics';

@Component({
  selector: 'app-price-list-alert',
  templateUrl: './price-list-alert.component.html',
  styleUrls: ['./price-list-alert.component.scss'],
})
export class PriceListAlertComponent implements OnInit {

  @Input() alert: IMagicMoreInfoAlert

  constructor() { }

  ngOnInit() {}

}
