import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isPrivate'
})
export class IsPrivatePipe implements PipeTransform {
  transform(product): boolean {
    if (!product.sizes || !product.sizes[0]) {
      return false;
    }
    // return !!(product.sizes[0].pricingBy !== 'customSize')


   return !!(product.sizes.find(size => size.pricingBy === 'customSize'));

  }


}
