import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicMoreInfoAlert, IMagicsInput, IMagicsParams, IPaperOption } from "../interfaces/magics";
import { MainMagics } from "./mainMagics";
import * as _ from "lodash";
import { MagicsService } from "../services/magics.service";
import { SettingService } from "../services/setting.service";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { IProductInput } from "../interfaces/products";
import { IPaperItem } from "../interfaces/paper";
import { TranslateProvider } from "../providers";
import { DataService } from "../data.service";

export class PagesMagic extends MainMagics {
  order: number = 12;
  data: any = {};
  calculatorInputs: IMagicsInput[] = [];

  constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
    super(magicsService, dataService);
    this.getPagesInputs();
  }

  async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput): Promise<IMagicMoreInfo> {
    return {
      machinesPrice: [],
      order: this.order,
    };
  }

  getPagesInputs() {
    const length = Array.from(Array(151).keys());

    let options = length.reduce((accumulator: any, currentValue) => {
      const newValue = currentValue === 0 ? 1 : currentValue * 4;
      accumulator.push({
        label: newValue.toString(),
        value: newValue,
      });
      return accumulator;
    }, []);
    this.calculatorInputs = [
      {
        name: "pages",
        type: "select",
        label: [{ text: this.translate.get("27_NUMBER_OF_PAGES") }],
        required: false,
        options,
      },
    ];
  }
}
