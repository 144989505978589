import { Component, Input, OnInit } from '@angular/core';
import { IDynamicInput } from '../../../interfaces/general';

@Component({
  selector: 'app-form-line',
  templateUrl: './form-line.component.html',
  styleUrls: ['./form-line.component.scss'],
})
export class FormLineComponent implements OnInit {
  @Input() inputs: IDynamicInput[] = [];
  @Input() parameter;
  @Input() flex = false;

  constructor() { }

  ngOnInit() {
    console.log('parameter', this.parameter);

  }

}
