import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { ICostumers } from '../interfaces/costumers';

@Pipe({
  name: 'examination'
})
export class ExaminationPipe implements PipeTransform {

  transform(costumers: ICostumers, text: any, choose): boolean {
    if (_.isEmpty(costumers)) {
      return false;
    }
    const find = (Object.values(costumers).find(costumer => costumer.name === text));
    const result = text.length && !choose && !find;
    return result;
  }

}
