import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-buttons',
  templateUrl: './footer-buttons.component.html',
  styleUrls: ['./footer-buttons.component.scss'],
})
export class FooterButtonsComponent implements OnInit {

  @Output() functionBack:  EventEmitter<Function>  = new EventEmitter();
  @Output() functionNext:  EventEmitter<Function>  = new EventEmitter();
  @Input() disabledPrev: boolean ;
  @Input() disabledNext: boolean ;
  @Input() isNextAndPrev: boolean ;

  constructor(public router: Router) { }

  ngOnInit() {}

}
