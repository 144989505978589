import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ICartTemplateMessages } from '../../interfaces/cart';

@Component({
  selector: 'app-template-messages',
  templateUrl: './template-messages.component.html',
  styleUrls: ['./template-messages.component.scss'],
})
export class TemplateMessagesComponent implements OnInit {
  @Input() messages: ICartTemplateMessages = {};
  img: string = environment.pinkExclamationMark;
  constructor() { }

  ngOnInit() {}

}
