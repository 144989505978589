import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubMenuTemplateComponent } from './sub-menu-template.component';
import { PipesModule } from '../../pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ComponentsPrintModule } from '../../components-print/components-print.module';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({

  declarations: [SubMenuTemplateComponent],
  imports: [
    ComponentsPrintModule,
    CommonModule,
    PipesModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    RouterModule
  ],
  exports: [SubMenuTemplateComponent]
})
export class SubMenuTemplateModule { }
