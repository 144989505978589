import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderDetailsComponent } from './order-details.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [OrderDetailsComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [OrderDetailsComponent]
})
export class OrderDetailsModule { }
