import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicsInput, IMagicsParams } from '../interfaces/magics';
import { MainMagics } from './mainMagics';
import * as _ from 'lodash';
import { MagicsService } from '../services/magics.service';
import { ICartDesign, ICartTemplate } from '../interfaces/cart';
import { SettingService } from '../services/setting.service';
import { IProductInput } from '../interfaces/products';
import { TranslateProvider } from '../providers';
import { DataService } from '../data.service';


export class Folding extends MainMagics {
    data: any = {};
    calculatorInputs: IMagicsInput[] = [
        {
            name: "machineID",
            type: "select",
            label: [{ text: this.translate.get("371_MACHINE") }],
            required: false,
            options: [],
            filter: [
                {
                    key: "parameter",
                    value: "40_folding",
                },
            ],
        },
        {
            name: "amountFolds",
            type: "select",
            label: [{ text: this.translate.get("668_THE_AMOUNT_OF_FOLDS_IN_THE_UNIT") }],
            required: false,
            options: [{
                label: `0 ${this.translate.get("669_FOLDS")}`,
                value: "0",
            },
            {
                label: `1 ${this.translate.get("40_folding")}`,
                value: "1",
            },
            {
                label: `2 ${this.translate.get("669_FOLDS")}`,
                value: "2",
            },
            {
                label: `3 ${this.translate.get("669_FOLDS")}`,
                value: "3",
            },
            {
                label: `4 ${this.translate.get("669_FOLDS")}`,
                value: "4",
            },
            {
                label: `5 ${this.translate.get("669_FOLDS")}`,
                value: "5",
            }
            ],
            defaultValue: "0"
        }
    ];

    constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
        super(magicsService, dataService);
    }


    getPrice(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, machinesKey?: string): number {
        const minimumPrice = _.get(magicData, 'minimumPrice', 0);
        const pricePerMeter = _.get(magicData, 'pricePerMeter', 0);
        const cuttingMeter = this.getCuttingMeter(design, template);
        const max = _.max([parseFloat(minimumPrice), (cuttingMeter * pricePerMeter)]);
        return this.settingService.machines[machinesKey] && this.settingService.machines[machinesKey].parameters.print ? max + (cuttingMeter * this.settingService.machines[machinesKey].pulsePrice) : max;
    }

    async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput): Promise<IMagicMoreInfo> {
        const machinesPrice = this.magicsService.getMagicAllPrices('folding', magicData, parameterPrice, count, design, template);

        const extensionFolding = this.getExtensionByName(template, "folding");
        const machineID = this.magicsService.getMachineID(template, design);
        const amountFolds = _.get(extensionFolding, "input.values.amountFolds", null);
        const printPoints = _.get(this.settingService.machines, [machineID, "parameters", "40_folding", "printPoints"], 1);
        const minPoints = _.get(this.settingService.machines, [machineID, "parameters", "40_folding", "minimumPoints"], 0);

        let objectCopies = this.magicsService.getNumberOfPages(design.size.width, design.size.height, template.print.copies, template)
        const copies = _.get(objectCopies, ["copies"], 0);
        const x = _.get(objectCopies, ["x"], 0);
        if (objectCopies) {
            let calcPoints = amountFolds * copies * x
            let basic: IMagicMessageLabelDescription[] = [];


            let points = 0;
            let sortPrintPoints = _.sortBy(printPoints, 'amountOfFolds');
            for (let i = 0; i < sortPrintPoints.length; i++) {
                if (sortPrintPoints[i].amountOfFolds <= copies) {
                    points = sortPrintPoints[i].pointsAmount
                }
                else {
                    break;
                }
            }

            let pointsNumber = calcPoints * points

            if (pointsNumber > minPoints) {
                basic.push({
                    label: `${this.translate.get("666_THE_AMOUNT_OF_FOLDS")}`,
                    description: calcPoints,
                });
                basic.push({
                    label: `${this.translate.get("547_PRICE_PER_POINT")}`,
                    description: points,
                });
            }
            else {
                basic.push({
                    label: `${this.translate.get("641_MINIMUM_POINTS")}`,
                    description: minPoints,
                });
                if (amountFolds != "0")
                    pointsNumber = minPoints
            }


            return {
                basic: basic,
                machinesPrice,
                data: {},
                pointsNumber: pointsNumber
            };
        }
    }


    isHiddenInput(): boolean {
        return false;
    }


    getCuttingMeter(design: ICartDesign, template: ICartTemplate): number {
        const copies = _.get(template, ['print', 'copies'], 0);
        const width = _.get(design, ['size', 'width'], 0) / 1000;
        const height = _.get(design, ['size', 'height'], 0) / 1000;
        return +(((width * 2) + (height * 2)) * copies).toFixed(2);
    }

}
